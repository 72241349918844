const isAmazon = window.location.href.indexOf('/amazon') > -1
const isFlipkart = window.location.href.indexOf('/flipkart') > -1
const isInstamart = window.location.href.indexOf('/instamart') > -1 
const isMyntra = window.location.href.indexOf('/myntra') > -1
const isBlinkit = window.location.href.indexOf('/blinkit') > -1

function formatWithTimezone(date, timeZone) {
    return new Intl.DateTimeFormat('en-US', {
      timeZone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }).format(date);
}

export const zoneToData = (localDate) => {
    const globalIdentifier = isAmazon ? 'selectedProfileId' : isFlipkart ? 'flipkartProfile' : isMyntra ? 'myntraProfile' : isInstamart ? 'instamartProfile' : isBlinkit ? 'blinkitProfile' : ''
    const globalProfileDetails = globalIdentifier ? JSON.parse(localStorage.getItem(globalIdentifier)) : {}
    localDate = formatWithTimezone(new Date(localDate), 'Asia/Kolkata')
    if(!globalProfileDetails?.timezone) return localDate
    localDate = formatWithTimezone(new Date(localDate), globalProfileDetails?.timezone)
    return localDate
}