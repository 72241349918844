import { useEffect, useState } from 'react';
import { useAmzContext } from 'src/contexts/AmzContext';
import { useFkContext } from 'src/contexts/FkContext';
import { useCommonContext } from 'src/contexts/CommonContext';
import { ReactComponent as SnapshotIcon } from 'src/assets/images/common/snapshot-white.svg';
import { ReactComponent as AmazonIcon } from 'src/assets/images/common/amazon-white.svg';
import { ReactComponent as MasterOverviewIcon } from 'src/assets/images/common/master-overview.svg';
import { ReactComponent as DaypartIconIcon } from 'src/assets/images/common/daypart.svg';
import { ReactComponent as DashboardIcon } from 'src/assets/images/common/ams-dash.svg';
import { ReactComponent as HectorGptIcon } from 'src/assets/images/common/chat.svg';
// import { ReactComponent as DspIcon } from 'src/assets/images/dsp/digital-transformation.svg';
import { ReactComponent as AmcDashboardIcon } from 'src/assets/images/common/dashboard.svg';
import { ReactComponent as AmckeyIconwhite } from 'src/assets/images/common/calender-white.svg';
import { Clock } from 'react-feather';

import { ALLOWED_PROFILE, CHATGPT_PROFILES } from 'src/views/pages/audience-report/utils';
import newMenuItems from 'src/new-menu-items';
import useApi from 'src/hooks/useApi';
import services from 'src/api/service';
import BidRuleIcon from 'src/assets/icons/BidRuleIcon';
import BudgetRuleIcon from 'src/assets/icons/BudgetRuleIcon';
import PlacementRuleIcon from 'src/assets/icons/PlacementRuleIcon';
import SearchTermRuleIcon from 'src/assets/icons/SearchTermRuleIcon';
import StatusRuleIcon from 'src/assets/icons/StatusRuleIcon';

const useNewMenuItems = () => {
  const [menuItems, setMenuItems] = useState([]);
  const { commonState } = useCommonContext();
  const { amzState } = useAmzContext();
  const { fkState } = useFkContext();
  const authToken = JSON.parse(localStorage.getItem('User'));
  const getProfile = JSON.parse(localStorage.getItem('selectedProfileId'));
  const profileIdCheck = useApi(services.postService);

  const [amcMenus, setAmcMenus] = useState([])

  useEffect(() => {
    profileIdCheck.request({
      url: '/amc/amcOnboardedusers',
      nodeAPI: 1,
      payload: {}
    });
  }, []);

  useEffect(() => {
    const { loading, data } = profileIdCheck;
    setAmcMenus(!loading && data && data.success == true ? [{title: 'AMC Console',
      icon: <AmazonIcon />,items:[{
        label: 'Audience',
        icon: <AmcDashboardIcon />,
        path: '/amazon-audience-report',
      },{
        label: 'Insights',
        icon: <AmckeyIconwhite />,
        path: '/amazon-insights',
      }]}] : []);
  }, [profileIdCheck.loading]);

  useEffect(() => {
    const isSuperAdmin = process.env.REACT_APP_ADMIN_ACCESS_EMAIL.indexOf(authToken?.email) > -1 || false;
    const {
      flipkartProfileStatus: flipkartState = false,
      profileStatus: amazonState = false,
      myntraProfileStatus: myntraState = false,
      blinkitProfileStatus: blinkitState = false,
      instamartProfileStatus: instamartState = false,
    } = commonState?.userInfo || {};

    const clientMenus = [];
    let menuData = [];

    // Amazon Menu Configuration
    if (amzState) {
      const amazonMenu = {
        title: 'Amazon',
        icon: <AmazonIcon />,
        items: [
          { label: 'Targeting 360', icon: <DashboardIcon />, path: '/amazon/targeting' },
          { label: 'Snapshot', icon: <SnapshotIcon />, path: '/amazon-snapshot' },
          { label: 'Master Overview', icon: <MasterOverviewIcon />, path: '/amazon-master-overview' },
          { label: 'Dayparting', icon: <DaypartIconIcon />, path: '/amazon-dayparting-performance' },
          { label: 'Ad Account History', icon: <Clock height="22px" width="22px" />, path: '/amazon-history' },
          ...(CHATGPT_PROFILES?.includes(String(getProfile?.profileId)) || CHATGPT_PROFILES?.length === 0 ? [
            {
              label: 'Hector GPT',
              icon: <HectorGptIcon />,
              path: '/amazon-chatgpt',
            }
          ] : []),
          ...(amcMenus.length ? amcMenus : []),
          // { label: 'DSP', icon: <DspIcon />, path: '/amazon-dsp' },
          // (getProfile?.accessLevel?.toLowerCase() == 'manager' && authToken?.isRuleEnabled == true) || (authToken?.isRuleEnabled == true && String(authToken?.email).toLowerCase().indexOf('@pexpo') > -1)
          ...(isSuperAdmin ||  authToken?.isRuleEnabled == true ? [{
            title: 'Automation Rules',
            icon: <AmazonIcon />,
            items: [
              { label: 'Budgets', icon: <BudgetRuleIcon fillColor='#fff' height="20px" width="20px" />, path: '/amazon-automated-rules/budget' },
              { label: 'Bid', icon: <BidRuleIcon fillColor='#fff' height="20px" width="20px" />, path: '/amazon-automated-rules/bid' },
              { label: 'Status', icon: <StatusRuleIcon fillColor='#fff' height="20px" width="20px"/>, path: '/amazon-automated-rules/status' },
              { label: 'Placement', icon: <PlacementRuleIcon fillColor='#fff' height="20px" width="20px"/>, path: '/amazon-automated-rules/placement' },
              { label: 'Search Term', icon: <StatusRuleIcon fillColor='#fff' height="20px" width="20px"/>, path: '/amazon-automated-rules/searchterm' },
            ],
          }] : [])
        ],
      };
      clientMenus.push(amazonMenu);
    }

    // Super Admin Condition
    if (isSuperAdmin) {
      menuData = [
        ...clientMenus,
        newMenuItems[1],
        myntraState && newMenuItems[2],
        blinkitState && newMenuItems[3],
        instamartState && newMenuItems[4],
        newMenuItems[5]
      ].filter(Boolean);
    } else if (flipkartState && !amazonState) {
      menuData = [
        newMenuItems[1],
        myntraState && newMenuItems[2],
        blinkitState && newMenuItems[3],
        instamartState && newMenuItems[4],
      ].filter(Boolean);
    } else if (flipkartState && amazonState) {
      menuData = [
        ...clientMenus,
        newMenuItems[1],
        myntraState && newMenuItems[2],
        blinkitState && newMenuItems[3],
        instamartState && newMenuItems[4],
      ].filter(Boolean);
    } else if (amazonState) {
      menuData = clientMenus;

      if (myntraState) {
        menuData.push(
          newMenuItems[2]
        );
      }
      if (blinkitState) {
        menuData.push(
          newMenuItems[3]
        );
      }
      if (instamartState) {
        menuData.push(
          newMenuItems[4]
        );
      }
    } else if (myntraState) {
      menuData = [
        newMenuItems[2]
      ];
    } else if (blinkitState) {
      menuData = [
        newMenuItems[3]
      ];
    } else if (instamartState) {
      menuData = [
        newMenuItems[4]
      ];
    }

    setMenuItems(menuData);

    return () => {
      setMenuItems([]);
    };
  }, [amzState.amazonProfiles, commonState, amcMenus]);

  return { menuItems };
};

export default useNewMenuItems;
