const SearchTermRuleIcon = ({ fillColor = "black", ...rest }) => {
    return (
        <svg width="31" height="34" viewBox="0 0 31 34" fill="none" xmlns="http://www.w3.org/2000/svg" { ...rest }>
            <path d="M27.8577 30.4583L30.2327 32.8333M19.1494 26.5C19.1494 27.7598 19.6499 28.968 20.5407 29.8588C21.4315 30.7496 22.6396 31.25 23.8994 31.25C25.1592 31.25 26.3674 30.7496 27.2582 29.8588C28.149 28.968 28.6494 27.7598 28.6494 26.5C28.6494 25.2402 28.149 24.032 27.2582 23.1412C26.3674 22.2504 25.1592 21.75 23.8994 21.75C22.6396 21.75 21.4315 22.2504 20.5407 23.1412C19.6499 24.032 19.1494 25.2402 19.1494 26.5Z" stroke={fillColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M27.0658 17.0003V7.10291C27.0659 6.97793 27.0413 6.85416 26.9935 6.73868C26.9457 6.62321 26.8755 6.51831 26.7871 6.42999L21.8028 1.44566C21.6248 1.26745 21.3833 1.16721 21.1314 1.16699H2.68242C2.43047 1.16699 2.18883 1.26708 2.01067 1.44524C1.83251 1.6234 1.73242 1.86504 1.73242 2.11699V31.8837C1.73242 32.1356 1.83251 32.3772 2.01067 32.5554C2.18883 32.7336 2.43047 32.8337 2.68242 32.8337H12.8158" stroke={fillColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M20.7324 1.16699V6.55033C20.7324 6.80228 20.8325 7.04392 21.0107 7.22208C21.1888 7.40024 21.4305 7.50033 21.6824 7.50033H27.0658" stroke={fillColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>  
    );
};

export default SearchTermRuleIcon;

