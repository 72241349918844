import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Row, Col } from 'react-bootstrap';
import React, { useContext, useEffect, useRef, useState } from 'react';

import {
  addDays,
  differenceInCalendarDays,
  eachDayOfInterval,
  endOfDay,
  endOfMonth,
  format,
  getDay,
  isSameDay,
  isWeekend,
  startOfDay,
  startOfMonth,
  sub,
  subDays,
  subMonths,
  subWeeks,
  startOfWeek,
  endOfWeek,
  subYears,
  startOfYear,
  endOfYear,
  add,
  addMonths,
  isThisMonth,
  isThisYear,
  differenceInDays
} from 'date-fns';
import Form from 'react-bootstrap/Form';
import { DateRangePicker } from 'react-date-range';
import { Popover, Whisper } from 'rsuite';
import Calender from 'src/assets/images/common/calender.svg';
import collapseIcon from 'src/assets/images/common/down-arrow.svg';
import collapseLight from 'src/assets/images/common/down-arrow-light.svg';
import TickIcon from 'src/assets/images/common/white-tick.svg';
import { useCommonContext } from 'src/contexts/CommonContext.js';
import { ThemeContext } from 'src/providers/themeProvider';
import Input from 'src/components/Form/Input.js';

import { CALENDAR_OPTIONS } from './report-targeting.constant.js';
import { usePayloadContext } from 'src/contexts/PayloadContext.js';
import { useSmartSearchContext } from 'src/contexts/SmartSearchContext.js';
import { useDayPartingContext } from 'src/contexts/DayPartingContext.js';
import isAfter from 'date-fns/isAfter';
import { zoneToData } from 'src/config/timezone.js';

const CollapseArrowIcon = () => {
  const [theme] = useContext(ThemeContext);
  return <img src={theme.theme === 'DARK' ? collapseLight : collapseIcon} alt="date_picker" className="wid-10 ml-3" />;
};

function dateFormat(dateString) {
  const date = new Date(dateString);
  return date;
}

export default function Calendar({
  availableColumns = [],
  setAvailableColumns,
  crossFilter = [],
  setCrossFilter,
  reportTitle = '',
  onEmitDateRange,
  classes = '',
  customclass = '',
  children,
  preDateSelected,
  isCustomCalender = 0,
  isCalendarOnly = 0,
  isSnapshot = window.location.href.indexOf('amazon/targeting') > -1 ||
    window.location.href.indexOf('sov') > -1 ||
    window.location.href.indexOf('master-overview') > -1 ||
    window.location.href.indexOf('snapshot') > -1 ||
    window.location.href.indexOf('amazon-dayparting') > -1,
  compare = 0,
  defaultCalendar = 0,
  isDirectCalendar = false,
  isDirectType = '',
  masterSearchCalendar = 0,
  calendarClasses,
  fromTable = 0,
  placement
}) {
  const { commonState, commonDispatch } = useCommonContext();
  const [crossFilterPayload, setCrossFilterPayload] = useState(crossFilter);
  const [tempDateRange, setTempDateRange] = useState(
    preDateSelected && Object.keys(preDateSelected).length > 0
      ? {
          ...preDateSelected,
          dates: [],
          compareDates: []
        }
      : {
          startDate: subDays(new Date(), 30),
          endDate: subDays(new Date(), 1),
          pastStartDate: subDays(new Date(), 60),
          pastEndDate: subDays(new Date(), 31),
          dates: [],
          compareDates: [],
          key: 'Last 30 Days'
        }
  );

  const tillToday =
    window.location.href.indexOf('user-activity') > -1 ||
    window.location.href.indexOf('amazon-dayparting') > -1 ||
    window.location.href.indexOf('summary/flipkart') > -1 ||
    window.location.href.indexOf('flipkart-snapshot') > -1 ||
    window.location.href.indexOf('flipkart-master-overview') > -1 ||
    window.location.href.indexOf('summary/myntra') > -1 ||
    window.location.href.indexOf('amazon-snapshot') > -1 ||
    window.location.href.indexOf('amazon-master-overview') > -1;

  const [showDatepicker, setShowdatepicker] = useState(false);
  const [dateRanges, setDateRanges] = useState([]);
  const [doCompare, setDoCompare] = useState(
    preDateSelected && Object.keys(preDateSelected).length > 0 && 'compare' in preDateSelected ? preDateSelected?.compare : false
  );
  const [compareSelected, doCompareSelected] = useState(
    preDateSelected && Object.keys(preDateSelected).length > 0 && 'compare' in preDateSelected ? preDateSelected?.compare : false
  );
  const [startFailureMessage, setStartFailureMessage] = useState('');
  const [endFailureMessage, setEndFailureMessage] = useState('');
  const [dateSelected, setDateSelected] = useState(
    preDateSelected && Object.keys(preDateSelected).length > 0
      ? preDateSelected
      : {
          startDate: subDays(new Date(), 30),
          endDate: subDays(new Date(), 1),
          pastStartDate: subDays(new Date(), 60),
          pastEndDate: subDays(new Date(), 31),
          key: 'Last 30 Days'
        }
  );
  const [disableButton, setDisableButton] = useState(true);
  const [selectedDateRange, setSelectedDateRange] = useState(
    preDateSelected && Object.keys(preDateSelected).length > 0
      ? [preDateSelected]
      : [
          {
            startDate: subDays(new Date(), 30),
            endDate: subDays(new Date(), 1),
            pastStartDate: subDays(new Date(), 60),
            pastEndDate: subDays(new Date(), 31),
            key: 'Last 30 Days'
          }
        ]
  );

  const { payloadState } = usePayloadContext();

  useEffect(() => {
    if (tempDateRange?.key === preDateSelected?.key) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [tempDateRange, preDateSelected]);

  useEffect(() => {
    if (
      payloadState.dateRange &&
      Object.keys(payloadState.dateRange).length > 0 &&
      JSON.stringify(payloadState.dateRange) != JSON.stringify(dateSelected)
    ) {
      setSelectedDateRange([payloadState.dateRange]);
      setDateSelected(payloadState.dateRange);
      setDoCompare(
        payloadState.dateRange && Object.keys(payloadState.dateRange).length > 0 && 'compare' in payloadState.dateRange
          ? payloadState.dateRange?.compare
          : false
      );
      doCompareSelected(
        payloadState.dateRange && Object.keys(payloadState.dateRange).length > 0 && 'compare' in payloadState.dateRange
          ? payloadState.dateRange?.compare
          : false
      );
    }
  }, [JSON.stringify(payloadState.dateRange)]);

  useEffect(() => {
    if (showDatepicker) {
      commonDispatch({
        type: 'UPDATE_OVERLAY_MODAL',
        payload: {
          modal: {
            show: true,
            closeCallBack: () => {
              setShowdatepicker(false);
            }
          }
        }
      });
      document.body.classList.add('overflow-hidden');
    } else {
      commonDispatch({
        type: 'UPDATE_OVERLAY_MODAL',
        payload: {
          modal: {
            show: false,
            closeCallBack: () => {}
          }
        }
      });
      document.body.classList.remove('overflow-hidden');
    }
  }, [showDatepicker]);

  const isDirectDateRef = useRef(JSON.stringify(preDateSelected));

  useEffect(() => {
    if (isDirectCalendar === true && isDirectDateRef.current != JSON.stringify(preDateSelected)) {
      isDirectDateRef.current = JSON.stringify(preDateSelected);

      setTempDateRange({
        ...preDateSelected,
        ...(isDirectType != 'between' ? { endDate: preDateSelected.startDate } : {}),
        dates: [],
        compareDates: []
      });

      setDateSelected({
        ...preDateSelected,
        ...(isDirectType != 'between' ? { endDate: preDateSelected.startDate } : {}),
        key: 'Custom'
      });

      setSelectedDateRange([
        {
          ...preDateSelected,
          ...(isDirectType != 'between' ? { endDate: preDateSelected.startDate } : {}),
          key: 'Custom'
        }
      ]);
    }
  }, [isDirectType, isDirectCalendar, preDateSelected]);

  const updateDateRangeFromCustom = (type, range) => {
    if (type == 'start') {
      const dateInfo = {
        ...tempDateRange,
        pastStartDate: range.start,
        pastEndDate: range.end,
        key: 'Custom'
      };

      console.log('dateInfo', { dateInfo });

      setTempDateRange(dateInfo);

      setSelectedDateRange([{ ...dateInfo, key: '' }]);
    } else if (type == 'end') {
      const dateInfo = {
        ...tempDateRange,
        pastStartDate: range.start,
        pastEndDate: range.end,
        key: 'Custom'
      };

      console.log('dateInfo', { dateInfo });

      setTempDateRange(dateInfo);

      setSelectedDateRange([{ ...dateInfo, key: '' }]);
    }
  };

  const isSameDateApplied =
    format(new Date(payloadState?.dateRange?.startDate), 'yyyy-mm-dd') == format(new Date(tempDateRange?.startDate), 'yyyy-mm-dd') &&
    format(new Date(payloadState?.dateRange?.endDate), 'yyyy-mm-dd') == format(new Date(tempDateRange?.endDate), 'yyyy-mm-dd') &&
    format(new Date(payloadState?.dateRange?.pastStartDate), 'yyyy-mm-dd') ==
      format(new Date(tempDateRange?.pastStartDate), 'yyyy-mm-dd') &&
    format(new Date(payloadState?.dateRange?.pastEndDate), 'yyyy-mm-dd') == format(new Date(tempDateRange?.pastEndDate), 'yyyy-mm-dd') &&
    tempDateRange?.key == payloadState?.dateRange?.key &&
    doCompare == preDateSelected?.compare
      ? true
      : false;

  const lastMonth = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);

  const DatePicker = (
    <Popover style={{ zIndex: '10000' }}>
      <div
        className={`date-picker-modal-one theme_date_pcker ${defaultCalendar ? calendarClasses : ''} ${
          selectedDateRange.length > 1 ? 'hide_range' : ''
        } bg-white`}
      >
        {/* LEFT SIDE */}
        <div className="static_rng pt-3 pb-2 pl-2">
          <h3 className="f-0092FF f-12 f-w-600 px-2">Date Presets</h3>
          <ul>
            {/* [ ...(isSnapshot === 1 ? [ { label: "Today" } ] : []) ].concat(CALENDAR_OPTIONS) */}
            {[...(tillToday ? [{ label: 'Today' }] : []), ...CALENDAR_OPTIONS]
              .concat(commonState?.dateInfo?.start_date ? [{ label: 'Lifetime' }] : [])
              .map((date_range_row, date_range_index) => {
                return (
                  <li
                    key={`RANGE_${date_range_index}`}
                    className={`${tempDateRange.key === date_range_row?.label ? 'active' : ''} cursor-pointer f-12`}
                    onClick={() => {
                      updateRangeView({ key: date_range_row?.label }, 1);
                      setDisableButton(false);
                    }}
                  >
                    {tempDateRange.key === date_range_row?.label || (tempDateRange.key == '' && date_range_row?.label == 'Custom') ? (
                      <img src={TickIcon} alt="select-date" className="slcd-dat" />
                    ) : null}
                    {date_range_row?.label}
                  </li>
                );
              })}
          </ul>
        </div>
        {/* LEFT SIDE */}

        {/* RIGHT SIDE */}
        <section className="px-2">
          <DateRangePicker
            // moveRangeOnFirstSelection={true}
            // retainEndDateOnFirstSelection={true}
            editableDateInputs={true}
            calendarFocus={isThisMonth(tempDateRange.startDate) == true ? 'backwards' : 'forwards'}
            ranges={[...selectedDateRange].concat([
              ...(doCompare == 1 && selectedDateRange?.length > 0
                ? [
                    {
                      startDate: dateFormat(selectedDateRange[0].pastStartDate),
                      endDate: dateFormat(selectedDateRange[0].pastEndDate),
                      color: '#ACACAC'
                    }
                  ]
                : [])
            ])}
            months={2}
            // shownDate={new Date(lastMonth)}
            // initialFocusedRange={{
            //   startDate: subMonths(new Date(), 1),
            //   endDate: new Date(),
            //   key:'Default'
            // }}
            onChange={(item) => {
              console.log('CHANGE', item);
              setDisableButton(false);
              if (item.range1) {
                console.log('item.range1', item);
                setSelectedDateRange([
                  {
                    startDate: dateFormat(item.range1.startDate),
                    endDate: dateFormat(item.range1.endDate),
                    key: item.range1.key != '' && item.range1.key != 'selection' ? item.range1.key : 'Custom'
                  }
                ]);
                updateRangeView({
                  startDate: dateFormat(item.range1.startDate),
                  endDate: dateFormat(item.range1.endDate),
                  key: item.range1.key != '' && item.range1.key != 'selection' ? item.range1.key : 'Custom'
                });
              } else if (item.selection) {
                setSelectedDateRange([
                  {
                    startDate: dateFormat(item.selection.startDate),
                    endDate: dateFormat(item.selection.endDate),
                    key: item.selection.key != '' && item.selection.key != 'selection' ? item.selection.key : 'Custom'
                  }
                ]);
                updateRangeView({
                  startDate: dateFormat(item.selection.startDate),
                  endDate: dateFormat(item.selection.endDate),
                  key: item.selection.key != '' && item.selection.key != 'selection' ? item.selection.key : 'Custom'
                });
              } else if (!item.range) {
                console.log('!item.range', item);
                console.log({ ...Object.values(item)[0], key: 'Custom' });
                // setSelectedDateRange([]);
                updateRangeView({ ...Object.values(item)[0], key: 'Custom' });
              }
            }}
            direction="horizontal"
            maxDate={tillToday ? new Date(zoneToData(addDays(new Date(), 0))) : new Date(zoneToData(addDays(new Date(), -1)))}
            staticRanges={[]}
          />
          {!isDirectCalendar ? (
            <div className="dat-pic-close bg-white d-flex flex-column px-2 pb-0">
              <div className="d-flex align-items-center">
                <Row className="wid-614">
                  <Col sm={6}>
                    <h4 className="f-10 f-w-600 mb-2">Selected Date Range</h4>
                  </Col>
                  <Col sm={6}>{isSnapshot && doCompare ? <h4 className={`f-10 f-w-600 mb-2`}>Compared Date Range</h4> : null}</Col>
                  <Col sm={6}>
                    <Row>
                      <Col sm={6} className={`pr-2 position-relative ${tempDateRange.key === 'Custom' ? 'disabled' : 'disabled'}`}>
                        <label className="f-333843 f-10 f-w-400 mb-1">From</label>
                        <div className="position-relative">
                          <img src={Calender} alt="date_picker" className="wid-20 mr-2 prefix-icon z-500 top-9" />
                          <input
                            type="date"
                            className="form-field cus_date-select"
                            value={format(selectedDateRange[0]?.startDate, 'yyyy-MM-dd')}
                            onChange={() => {}}
                          />
                        </div>
                      </Col>
                      <Col sm={6} className={`pl-1 position-relative ${tempDateRange.key === 'Custom' ? 'disabled' : 'disabled'}`}>
                        <label className="f-333843 f-10 f-w-400 mb-1">To</label>
                        <div className="position-relative">
                          <img src={Calender} alt="date_picker" className="wid-20 mr-2 prefix-icon z-500 top-9" />
                          <input
                            type="date"
                            className="form-field cus_date-select"
                            value={format(selectedDateRange[0]?.endDate, 'yyyy-MM-dd')}
                            onChange={() => {}}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  {isSnapshot && tempDateRange.key != 'Lifetime' ? (
                    <Col sm={6} className={`${!isSnapshot ? 'card-hidden' : ''}`}>
                      {doCompare ? (
                        <Row>
                          <Col sm={6} className={`pr-2 position-relative ${tempDateRange.key === 'Custom' ? '' : 'disabled'}`}>
                            <label className="f-333843 f-10 f-w-400 mb-1">From</label>
                            <div className="position-relative">
                              <img src={Calender} alt="date_picker" className="wid-20 mr-2 prefix-icon z-500 top-9" />
                              <input
                                type="date"
                                className="form-field cus_date-select"
                                max={format(subDays(tempDateRange?.startDate, 1), 'yyyy-MM-dd')}
                                value={format(selectedDateRange[0]?.pastStartDate, 'yyyy-MM-dd')}
                                onChange={(event) => {
                                  const duration =
                                    eachDayOfInterval({ start: tempDateRange.startDate, end: tempDateRange.endDate }).length - 1;
                                  const addCustomDays = addDays(new Date(event.target.value), duration);
                                  console.log('duration', { duration, start: new Date(event.target.value), end: addCustomDays });
                                  if (
                                    new Date(event.target.value) < new Date() &&
                                    new Date(addCustomDays) < new Date(tempDateRange.startDate)
                                  ) {
                                    updateDateRangeFromCustom('start', {
                                      start: new Date(event.target.value),
                                      end: addCustomDays
                                    });
                                    setStartFailureMessage('');
                                  } else {
                                    console.log('Duration Not Matched');
                                    event.preventDefault();
                                    setStartFailureMessage('Please select the valid date');
                                    setTimeout(() => {
                                      setStartFailureMessage('');
                                    }, 3000);
                                  }
                                }}
                              />
                            </div>
                            {startFailureMessage != '' ? <p className="text-danger f-10">{startFailureMessage}</p> : ''}
                          </Col>
                          <Col sm={6} className={`pl-1 position-relative ${tempDateRange.key === 'Custom' ? '' : 'disabled'}`}>
                            <label className="f-333843 f-10 f-w-400 mb-1">To</label>
                            <div className="position-relative">
                              <img src={Calender} alt="date_picker" className="wid-20 mr-2 prefix-icon z-500 top-9" />
                              <input
                                type="date"
                                className="form-field cus_date-select"
                                max={format(subDays(tempDateRange?.startDate, 1), 'yyyy-MM-dd')}
                                value={format(selectedDateRange[0]?.pastEndDate, 'yyyy-MM-dd')}
                                onChange={(event) => {
                                  const duration =
                                    eachDayOfInterval({ start: tempDateRange.startDate, end: tempDateRange.endDate }).length - 1;
                                  const subCustomDays = subDays(new Date(event.target.value), duration);
                                  console.log('duration', { duration, start: new Date(event.target.value), end: subCustomDays });
                                  if (new Date(event.target.value) < new Date() && subCustomDays < new Date(tempDateRange.startDate)) {
                                    updateDateRangeFromCustom('start', {
                                      start: subCustomDays,
                                      end: new Date(event.target.value)
                                    });
                                    setEndFailureMessage('');
                                  } else {
                                    console.log('duration Not Matched');
                                    event.preventDefault();
                                    setEndFailureMessage('Please select the valid date');
                                    setTimeout(() => {
                                      setEndFailureMessage('');
                                    }, 3000);
                                  }
                                }}
                              />
                            </div>
                            {endFailureMessage != '' ? <p className="text-danger f-10">{endFailureMessage}</p> : ''}
                          </Col>
                        </Row>
                      ) : null}
                    </Col>
                  ) : null}
                </Row>
              </div>
              <section
                className={`w-100 d-flex align-items-center mt-2 py-2 border-top justify-content-${
                  fromTable != 1 && isSnapshot && tempDateRange.key != 'Lifetime' ? 'between' : 'end'
                }`}
              >
                {fromTable != 1 && isSnapshot && tempDateRange.key != 'Lifetime' ? (
                  <div className="d-flex align-items-center gap-15 mr-5 pr-3">
                    <Form>
                      <Form.Check
                        className="f-12 d-flex align-items-center f-10 f-w-600 date_tog"
                        type="switch"
                        id="custom-switch"
                        onChange={(event) => {
                          if (event.target.checked === true) {
                            setDoCompare(1);
                            setDisableButton(false);
                          } else if (event.target.checked === false) {
                            setDoCompare(0);
                            setDisableButton(false);
                          }
                        }}
                        checked={doCompare == 1}
                        label="Compare"
                      />
                    </Form>
                  </div>
                ) : null}
                <div className="d-flex align-items-center gap-20">
                  <p
                    className="gray_btn cursor-pointer f-12 f-w-400 border-0 dat_btn"
                    onClick={() => {
                      setShowdatepicker(false);
                    }}
                  >
                    Cancel
                  </p>
                  <p
                    className={`blue_btn m-0 cursor-pointer f-12 f-w-400 dat_btn ${isSameDateApplied ? 'disabled' : ''}`}
                    onClick={() => {
                      saveDateRange();
                    }}
                  >
                    Apply
                  </p>
                </div>
              </section>
            </div>
          ) : null}
        </section>
        {/* RIGHT SIDE */}
      </div>
    </Popover>
  );

  useEffect(() => {
    setCrossFilterPayload(crossFilter);
  }, [JSON.stringify(crossFilter)]);

  const updateRangeView = (rangeInfo, from = 0) => {
    // const rangeInfo = selectedDateRange[0];
    console.log('updateRangeView', rangeInfo);

    let dateInfo = {};
    const currentDate = new Date();

    if ((rangeInfo.key === 'Today' || rangeInfo.key == 'Custom') && from == 1) {
      if (rangeInfo.key == 'Custom') {
        const dateInfo = { ...tempDateRange, key: 'Custom' };
        setTempDateRange(dateInfo);
        setSelectedDateRange([{ ...dateInfo, key: '' }]);
      } else {
        dateInfo = {
          startDate: new Date(zoneToData(subDays(new Date(), 0))),
          endDate: new Date(zoneToData(subDays(new Date(), 0))),
          pastStartDate: new Date(zoneToData(subDays(new Date(), 1))),
          pastEndDate: new Date(zoneToData(subDays(new Date(), 1))),
          key: rangeInfo.key
        };
        setTempDateRange(dateInfo);
        setSelectedDateRange([{ ...dateInfo, key: '' }]);
      }
    } else if (rangeInfo.key === 'Yesterday' && from == 1) {
      dateInfo = {
        startDate: new Date(zoneToData(subDays(new Date(), 1))),
        endDate: new Date(zoneToData(subDays(new Date(), 1))),
        pastStartDate: new Date(zoneToData(subDays(new Date(), 2))),
        pastEndDate: new Date(zoneToData(subDays(new Date(), 2))),
        key: 'Yesterday'
      };
      setTempDateRange(dateInfo);
      setSelectedDateRange([{ ...dateInfo, key: '' }]);
    } else if (rangeInfo.key === 'This Month' && from == 1) {
      const startOfThisMonth = startOfMonth(new Date());
      const endOfThisMonth = addDays(new Date(), -1) < startOfMonth(new Date()) ? startOfMonth(new Date()) : addDays(new Date(), -1);

      const currentWkInterval = eachDayOfInterval({ start: startOfMonth(new Date()), end: endOfThisMonth }).length - 1;

      const prevStartOfMonth = new Date(zoneToData(startOfMonth(subMonths(currentDate, 1))));
      const prevEndOfMonth = new Date(zoneToData(addDays(prevStartOfMonth, currentWkInterval)));

      dateInfo = {
        startDate: startOfThisMonth,
        endDate: endOfThisMonth,
        pastStartDate: prevStartOfMonth,
        pastEndDate: prevEndOfMonth,
        key: 'This Month'
      };

      setTempDateRange(dateInfo);
      setSelectedDateRange([{ ...dateInfo, key: '' }]);
    } else if (rangeInfo.key === 'Last 30 Days' && from == 1) {
      dateInfo = {
        startDate: new Date(zoneToData(subDays(new Date(), 30))),
        endDate: new Date(zoneToData(subDays(new Date(), 1))),
        pastStartDate: new Date(zoneToData(subDays(new Date(), 60))),
        pastEndDate: new Date(zoneToData(subDays(new Date(), 31))),
        key: 'Last 30 Days'
      };
      setTempDateRange(dateInfo);
      setSelectedDateRange([{ ...dateInfo, key: '' }]);
    } else if (rangeInfo.key === 'Last Month' && from == 1) {
      // Get the start and end dates for the current month
      const startDate = startOfMonth(subMonths(currentDate, 1));
      const endDate = endOfMonth(subMonths(currentDate, 1));

      // Calculate the number of days in the current month
      const numberOfDays = differenceInDays(endDate, startDate) + 1;

      // Get the start date for the past month
      const pastStartDate = startOfMonth(subMonths(currentDate, 2));

      // Calculate the end date for the past month to match the number of days in the current month
      const pastEndDate = addDays(pastStartDate, numberOfDays - 1);

      dateInfo = {
        startDate: new Date(zoneToData(startDate)),
        endDate: new Date(zoneToData(endDate)),
        pastStartDate: new Date(zoneToData(pastStartDate)),
        pastEndDate: new Date(zoneToData(pastEndDate)),
        key: 'Last Month'
      };

      setTempDateRange(dateInfo);
      setSelectedDateRange([{ ...dateInfo, key: '' }]);
    } else if (rangeInfo.key === 'Last 30 Days' && from == 1) {
      dateInfo = {
        startDate: new Date(zoneToData(subDays(new Date(), 30))),
        endDate: new Date(zoneToData(subDays(new Date(), 1))),
        pastStartDate: new Date(zoneToData(subDays(new Date(), 60))),
        pastEndDate: new Date(zoneToData(subDays(new Date(), 31))),
        key: 'Last 30 Days'
      };
      setTempDateRange(dateInfo);
      setSelectedDateRange([{ ...dateInfo, key: '' }]);
    } else if (rangeInfo.key === 'This Year' && from == 1) {
      const startOfThisYear = new Date(new Date().getFullYear(), 0, 1);
      const endOfThisYear = addDays(new Date(), -1);

      const currentWkInterval = eachDayOfInterval({ start: startOfThisYear, end: endOfThisYear }).length - 1;

      const prevStartOfYear = new Date(new Date().getFullYear() - 1, 0, 1);
      const prevEndOfYear = addDays(prevStartOfYear, currentWkInterval - 1);

      dateInfo = {
        startDate: new Date(zoneToData(startOfThisYear)),
        endDate: new Date(zoneToData(endOfThisYear)),
        pastStartDate: new Date(zoneToData(prevStartOfYear)),
        pastEndDate: new Date(zoneToData(prevEndOfYear)),
        key: 'This Year'
      };

      console.log('dateInfo', { dateInfo });
      setTempDateRange(dateInfo);
      setSelectedDateRange([{ ...dateInfo, key: '' }]);
    } else if (rangeInfo.key === 'Last Year' && from == 1) {
      const currentDate = new Date();

      dateInfo = {
        startDate: new Date(zoneToData(startOfYear(subYears(currentDate, 1)))),
        endDate: new Date(zoneToData(endOfYear(subYears(currentDate, 1)))),
        pastStartDate: new Date(zoneToData(startOfYear(subYears(currentDate, 2)))),
        pastEndDate: new Date(zoneToData(endOfYear(subYears(currentDate, 2)))),
        key: 'Last Year'
      };

      setTempDateRange(dateInfo);
      setSelectedDateRange([{ ...dateInfo, key: '' }]);
    } else if (rangeInfo.key === 'Last 7 Days' && from == 1) {
      dateInfo = {
        startDate: new Date(zoneToData(startOfDay(subDays(currentDate, 7)))),
        endDate: new Date(zoneToData(addDays(new Date(), -1))),
        pastStartDate: new Date(zoneToData(startOfDay(subDays(currentDate, 14)))),
        pastEndDate: new Date(zoneToData(endOfDay(subDays(currentDate, 8)))),
        key: 'Last 7 Days'
      };
      setTempDateRange(dateInfo);
      setSelectedDateRange([{ ...dateInfo, key: '' }]);
    } else if (rangeInfo.key === 'Last 14 Days' && from == 1) {
      dateInfo = {
        startDate: new Date(zoneToData(startOfDay(subDays(currentDate, 14)))),
        endDate: new Date(zoneToData(addDays(new Date(), -1))),
        pastStartDate: new Date(zoneToData(startOfDay(subDays(currentDate, 28)))),
        pastEndDate: new Date(zoneToData(endOfDay(subDays(currentDate, 15)))),
        key: 'Last 14 Days'
      };
      setTempDateRange(dateInfo);
      setSelectedDateRange([{ ...dateInfo, key: '' }]);
    } else if (rangeInfo.key === 'This Week' && from == 1) {

      function getPreviousPeriod(startDate, endDate) {
        // Calculate the duration between startDate and endDate
        const duration = eachDayOfInterval({ start: startDate, end: endDate });

        // Subtract the duration from the start and end dates to get the previous period
        const prevStartDate = sub(startDate, { days: duration.length });
        const prevEndDate = sub(endDate, { days: duration.length });

        return { prevStartDate, prevEndDate };
      }

      const currentDate = new Date();
      const startOfToday = startOfDay(subDays(new Date(), 1));

      const startOfWk = startOfWeek(startOfToday);
      const endOfWk = endOfDay(subDays(new Date(), 1));

      const { prevStartDate, prevEndDate } = getPreviousPeriod(startOfWk, endOfWk);

      const currentWkInterval = eachDayOfInterval({ start: startOfWk, end: endOfWk }).length - 1;

      const prevStartOfWeek = startOfWeek(prevStartDate);
      const prevEndOfWeek = addDays(prevStartOfWeek, currentWkInterval);

      dateInfo = {
        startDate: new Date(zoneToData(startOfWk)),
        endDate: new Date(zoneToData(endOfWk)),
        pastStartDate: new Date(zoneToData(prevStartOfWeek)),
        pastEndDate: new Date(zoneToData(prevEndOfWeek)),
        key: 'This Week'
      };
      setTempDateRange(dateInfo);

      setSelectedDateRange([{ ...dateInfo, key: '' }]);
    } else if (rangeInfo.key === 'Last Week' && from == 1) {
      function getPreviousPeriod(startDate, endDate) {
        // Calculate the duration between startDate and endDate
        const duration = eachDayOfInterval({ start: startDate, end: endDate });

        // Subtract the duration from the start and end dates to get the previous period
        const prevStartDate = sub(startDate, { days: duration.length });
        const prevEndDate = sub(endDate, { days: duration.length });

        return { prevStartDate, prevEndDate };
      }

      const currentDate = subDays(new Date(), 1);
      const startOfToday = startOfDay(currentDate);

      const startOfWk = startOfWeek(subWeeks(startOfToday, 1));
      const endOfWk = endOfWeek(subWeeks(startOfToday, 1));

      const { prevStartDate, prevEndDate } = getPreviousPeriod(startOfWk, endOfWk);

      dateInfo = {
        startDate: new Date(zoneToData(startOfWk)),
        endDate: new Date(zoneToData(endOfWk)),
        pastStartDate: new Date(zoneToData(prevStartDate)),
        pastEndDate: new Date(zoneToData(prevEndDate)),
        key: 'Last Week'
      };
      setTempDateRange(dateInfo);
      setSelectedDateRange([{ ...dateInfo, key: '' }]);
    } else if (rangeInfo.key === 'Last 7 Weeks' && from == 1) {
      dateInfo = {
        startDate: new Date(zoneToData(subWeeks(subDays(currentDate, 1), 7))),
        endDate: new Date(zoneToData(subDays(currentDate, 1))),
        pastStartDate: new Date(zoneToData(subDays(subWeeks(subDays(currentDate, 1), 14), 1))),
        pastEndDate: new Date(zoneToData(subDays(subWeeks(subDays(currentDate, 1), 7), 1))),
        key: 'Last 7 Weeks'
      };
      console.log('dateInfo', { dateInfo });
      setTempDateRange(dateInfo);
      setSelectedDateRange([{ ...dateInfo, key: '' }]);
    } else if (rangeInfo.key === 'Last 7 Months' && from == 1) {
      dateInfo = {
        startDate: new Date(zoneToData(startOfMonth(subMonths(currentDate, 7)))),
        endDate: new Date(zoneToData(endOfMonth(subMonths(currentDate, 1)))),
        pastStartDate: new Date(zoneToData(startOfMonth(subMonths(currentDate, 14)))),
        pastEndDate: new Date(zoneToData(endOfMonth(subMonths(currentDate, 8)))),
        key: 'Last 7 Months'
      };
      console.log('dateInfo', { dateInfo });

      setTempDateRange(dateInfo);
      setSelectedDateRange([{ ...dateInfo, key: '' }]);
    } else if (rangeInfo.key === 'Last 7 Weekdays' && from == 1) {
      // Initialize counters
      let last7WeekdaysCount = 0;
      let beforeLast7WeekdaysCount = 0;

      // Arrays to store the date ranges
      const last7Weekdays = [];
      const beforeLast7Weekdays = [];

      // Iterate from the current date backwards to find the dates
      while (last7WeekdaysCount < 7 || beforeLast7WeekdaysCount < 7) {
        const currentDateCopy = new Date(currentDate);

        // Subtract a day from the current date
        currentDateCopy.setDate(currentDateCopy.getDate() - 1);

        // Check if the current date is a weekday (not a weekend)
        if (!isWeekend(currentDateCopy)) {
          if (last7WeekdaysCount < 7) {
            last7Weekdays.push({
              start: startOfDay(currentDateCopy),
              end: endOfDay(currentDateCopy)
            });
            last7WeekdaysCount++;
          } else if (beforeLast7WeekdaysCount < 7) {
            beforeLast7Weekdays.push({
              start: startOfDay(currentDateCopy),
              end: endOfDay(currentDateCopy)
            });
            beforeLast7WeekdaysCount++;
          }
        }

        // Move to the previous day
        currentDate.setDate(currentDate.getDate() - 1);
      }

      console.log('updateRangeView', { last7Weekdays, beforeLast7Weekdays });

      const last7Week = last7Weekdays
        .sort((rowA, rowB) => Number(rowA.start) - Number(rowB.start))
        .map((dateItem, index) => {
          return {
            startDate: new Date(zoneToData(dateItem.start)),
            endDate: new Date(zoneToData(dateItem.end)),
            pastStartDate: new Date(zoneToData(dateItem.start)),
            pastEndDate: new Date(zoneToData(dateItem.end)),
            key: ''
          };
        });

      const beforeLast7Week = beforeLast7Weekdays
        .sort((rowA, rowB) => Number(rowA.start) - Number(rowB.start))
        .map((dateItem, index) => {
          return {
            startDate: new Date(zoneToData(dateItem.start)),
            endDate: new Date(zoneToData(dateItem.end)),
            pastStartDate: new Date(zoneToData(dateItem.start)),
            pastEndDate: new Date(zoneToData(dateItem.end)),
            key: ''
          };
        });

      if (beforeLast7Week && last7Week) {
        const rangesSelected = beforeLast7Week.concat(last7Week);

        console.log('RANGES', rangesSelected);
        // doCompare ? rangesSelected : last7Week
        setSelectedDateRange(last7Week);

        dateInfo = {
          startDate: last7Weekdays.sort((rowA, rowB) => Number(rowA.start) - Number(rowB.start))[0].start,
          endDate: last7Weekdays.sort((rowA, rowB) => Number(rowA.start) - Number(rowB.start))[last7Weekdays.length - 1].end,
          pastStartDate: beforeLast7Weekdays.sort((rowA, rowB) => Number(rowA.start) - Number(rowB.start))[0].start,
          pastEndDate: beforeLast7Weekdays.sort((rowA, rowB) => Number(rowA.start) - Number(rowB.start))[beforeLast7Weekdays.length - 1]
            .end,
          dates: last7Week.map((row) => row.startDate),
          compareDates: beforeLast7Week.map((row) => row.startDate),
          key: 'Last 7 Weekdays',
          current: last7Week,
          previous: beforeLast7Week
        };

        setTempDateRange(dateInfo);
      }
    } else if (rangeInfo.key === 'Last 7 Weekends' && from == 1) {
      // Initialize counters
      let last7WeekdaysCount = 0;
      let beforeLast7WeekdaysCount = 0;

      // Arrays to store the date ranges
      const last7Weekdays = [];
      const beforeLast7Weekdays = [];

      // Iterate from the current date backwards to find the dates
      while (last7WeekdaysCount < 7 || beforeLast7WeekdaysCount < 7) {
        const currentDateCopy = new Date(currentDate);

        // Subtract a day from the current date
        currentDateCopy.setDate(currentDateCopy.getDate() - 1);

        // Check if the current date is a weekday (not a weekend)
        if (isWeekend(currentDateCopy)) {
          if (last7WeekdaysCount < 7) {
            last7Weekdays.push({
              start: startOfDay(currentDateCopy),
              end: endOfDay(currentDateCopy)
            });
            last7WeekdaysCount++;
          } else if (beforeLast7WeekdaysCount < 7) {
            beforeLast7Weekdays.push({
              start: startOfDay(currentDateCopy),
              end: endOfDay(currentDateCopy)
            });
            beforeLast7WeekdaysCount++;
          }
        }

        // Move to the previous day
        currentDate.setDate(currentDate.getDate() - 1);
      }

      console.log('updateRangeView', { last7Weekdays, beforeLast7Weekdays });

      const last7Week = last7Weekdays
        .sort((rowA, rowB) => Number(rowA.start) - Number(rowB.start))
        .map((dateItem, index) => {
          return {
            startDate: new Date(zoneToData(dateItem.start)),
            endDate: new Date(zoneToData(dateItem.end)),
            pastStartDate: new Date(zoneToData(dateItem.start)),
            pastEndDate: new Date(zoneToData(dateItem.end)),
            key: ''
          };
        });

      const beforeLast7Week = beforeLast7Weekdays
        .sort((rowA, rowB) => Number(rowA.start) - Number(rowB.start))
        .map((dateItem, index) => {
          return {
            startDate: new Date(zoneToData(dateItem.start)),
            endDate: new Date(zoneToData(dateItem.end)),
            pastStartDate: new Date(zoneToData(dateItem.start)),
            pastEndDate: new Date(zoneToData(dateItem.end)),
            key: ''
          };
        });

      if (beforeLast7Week && last7Week) {
        const rangesSelected = beforeLast7Week.concat(last7Week);

        console.log('RANGES', rangesSelected);
        // doCompare ? rangesSelected : last7Week
        setSelectedDateRange(last7Week);

        dateInfo = {
          startDate: last7Weekdays.sort((rowA, rowB) => Number(rowA.start) - Number(rowB.start))[0].start,
          endDate: last7Weekdays.sort((rowA, rowB) => Number(rowA.start) - Number(rowB.start))[last7Weekdays.length - 1].end,
          pastStartDate: beforeLast7Weekdays.sort((rowA, rowB) => Number(rowA.start) - Number(rowB.start))[0].start,
          pastEndDate: beforeLast7Weekdays.sort((rowA, rowB) => Number(rowA.start) - Number(rowB.start))[beforeLast7Weekdays.length - 1]
            .end,
          dates: last7Week.map((row) => row.startDate),
          compareDates: beforeLast7Week.map((row) => row.startDate),
          key: 'Last 7 Weekends',
          current: last7Week,
          previous: beforeLast7Week
        };

        setTempDateRange(dateInfo);
      }
    } else if (rangeInfo.key === 'Last 7 Same day' && from == 1) {
      // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
      const currentDayOfWeek = getDay(currentDate);

      // Initialize an array to store the date ranges for the last 7 Mondays from the last 7 weeks
      const last7SameDays = [];

      // Calculate the date ranges for the last 7 Mondays from the last 7 weeks
      for (let i = 0; i < 8; i++) {
        const targetDay = subWeeks(currentDate, i); // Subtract weeks for the same day of the week

        // Check if the day matches Monday
        if (getDay(targetDay) === currentDayOfWeek && !isSameDay(targetDay, currentDate)) {
          const targetDate = eachDayOfInterval({ start: targetDay, end: targetDay });
          last7SameDays.push({ start: targetDate[0], end: targetDate[0] });
        }
      }

      // Initialize an array to store the date ranges for the 7 Mondays before the last 7 Mondays
      const beforeLast7SameDays = [];

      // Calculate the date ranges for the 7 Mondays before the last 7 Mondays
      for (let i = 8; i < 15; i++) {
        const targetDay = subWeeks(currentDate, i); // Subtract weeks for the same day of the week

        // Check if the day matches Monday
        if (getDay(targetDay) === currentDayOfWeek && !isSameDay(targetDay, currentDate)) {
          const targetDate = eachDayOfInterval({ start: targetDay, end: targetDay });
          beforeLast7SameDays.push({ start: targetDate[0], end: targetDate[0] });
        }
      }

      if (last7SameDays && beforeLast7SameDays) {
        console.log({ last7SameDays, beforeLast7SameDays });

        const last7Week = last7SameDays
          .sort((rowA, rowB) => Number(rowA.start) - Number(rowB.start))
          .map((dateItem, index) => {
            return {
              startDate: dateItem.start,
              endDate: dateItem.end,
              pastStartDate: dateItem.start,
              pastEndDate: dateItem.end,
              key: 'Last 7 Same day'
            };
          });

        const beforeLast7Week = beforeLast7SameDays
          .sort((rowA, rowB) => Number(rowA.start) - Number(rowB.start))
          .map((dateItem, index) => {
            return {
              startDate: dateItem.start,
              endDate: dateItem.end,
              pastStartDate: dateItem.start,
              pastEndDate: dateItem.end,
              key: 'Last 7 Same day'
            };
          });

        if (beforeLast7Week && last7Week) {
          const rangesSelected = beforeLast7Week.concat(last7Week);

          console.log('RANGES', rangesSelected);
          // doCompare ? rangesSelected : last7Week
          setSelectedDateRange(last7Week);

          dateInfo = {
            startDate: last7SameDays.sort((rowA, rowB) => Number(rowA.start) - Number(rowB.start))[0].start,
            endDate: last7SameDays.sort((rowA, rowB) => Number(rowA.end) - Number(rowB.end))[last7SameDays.length - 1].end,
            pastStartDate: beforeLast7SameDays.sort((rowA, rowB) => Number(rowA.start) - Number(rowB.start))[0].start,
            pastEndDate: beforeLast7SameDays.sort((rowA, rowB) => Number(rowA.end) - Number(rowB.end))[beforeLast7SameDays.length - 1].end,
            dates: last7Week.map((row) => row.startDate),
            compareDates: beforeLast7Week.map((row) => row.startDate),
            key: 'Last 7 Same day',
            current: last7Week,
            previous: beforeLast7Week
          };

          setTempDateRange(dateInfo);

          console.log('RANGES', rangesSelected);

          // doCompare ? rangesSelected : last7Week
          setSelectedDateRange(last7Week);
        }
      }

      console.log({ last7SameDays, beforeLast7SameDays });
    } else if (rangeInfo.key === 'Lifetime' && from == 1) {
      const getDaysAvailable = differenceInCalendarDays(
        new Date(commonState?.dateInfo?.end_date),
        new Date(commonState?.dateInfo?.start_date)
      );
      dateInfo = {
        startDate: new Date(zoneToData(new Date(commonState?.dateInfo?.start_date))),
        endDate: new Date(zoneToData(new Date(commonState?.dateInfo?.end_date))),
        pastStartDate: new Date(zoneToData(subDays(new Date(commonState?.dateInfo?.start_date), getDaysAvailable))),
        pastEndDate: new Date(zoneToData(subDays(new Date(commonState?.dateInfo?.start_date), 1))),
        key: 'Lifetime'
      };
      setDoCompare(0);
      console.log('dateInfo', { getDaysAvailable, dateInfo });
      setTempDateRange(dateInfo);
      setDoCompare(0);
      setSelectedDateRange([{ ...dateInfo, key: '' }]);
    } else if (rangeInfo.key === 'Custom') {
      function getPreviousPeriod(startDate, endDate) {
        // Calculate the duration between startDate and endDate
        const duration = eachDayOfInterval({ start: startDate, end: endDate });

        // Subtract the duration from the start and end dates to get the previous period
        const prevStartDate = sub(startDate, { days: duration.length });
        const prevEndDate = sub(endDate, { days: duration.length });

        return { prevStartDate, prevEndDate };
      }

      const { prevStartDate, prevEndDate } = getPreviousPeriod(rangeInfo.startDate, rangeInfo.endDate);
      console.log('previousInfo', { prevStartDate, prevEndDate });

      dateInfo = {
        startDate: rangeInfo.startDate,
        endDate: rangeInfo.endDate,
        pastStartDate: prevStartDate,
        pastEndDate: prevEndDate,
        key: 'Custom'
      };

      console.log('dateInfo', { dateInfo });

      setTempDateRange(dateInfo);

      setSelectedDateRange([{ ...dateInfo, key: '' }]);
    }
  };

  const saveDateRange = () => {
    if (tempDateRange) {
      setShowdatepicker(false);
      doCompareSelected(doCompare);
      setDateSelected(tempDateRange);
      setDisableButton(true);
    }
  };

  // useEffect(() => {
  //   const getDateRanges = ['Last 7 Days', 'Last 7 Months', 'Last 7 Weekdays', 'Last 7 Weekends'];

  //   let getDateRangeResponse = [];

  //   const getRanges = async () => {
  //     await getDateRanges.forEach(async (range) => {
  //       if (range === 'Last 7 Days') {
  //         const response = await getlast7WeeksRange();
  //         getDateRangeResponse.push(response);
  //       } else if (range === 'Last 7 Months') {
  //         const response = await getlast7MonthsRange();
  //         getDateRangeResponse.push(response);
  //       } else if (range === 'Last 7 Weekdays') {
  //         const response = await getLast7WeekDaysRange();
  //         getDateRangeResponse.push(response);
  //       } else if (range === 'Last 7 Weekends') {
  //         const response = await getlast7MonthsRange();
  //         getDateRangeResponse.push(response);
  //       }
  //       if (getDateRanges.length == getDateRangeResponse.length) {
  //         setDateRanges(getDateRangeResponse);
  //       }
  //     });
  //   };

  //   getRanges();

  //   return () => {
  //     getDateRangeResponse = [];
  //   };
  // }, []);

  useEffect(() => {
    onEmitDateRange(dateSelected, compareSelected, '');
  }, [dateSelected, compareSelected]);

  const { smartSearchState } = useSmartSearchContext();
  let isSmartSearchEnabled =
    smartSearchState?.filter_object?.dateInfo && Object.keys(smartSearchState?.filter_object?.dateInfo).length > 0 ? true : false;
  let defaultDateInfo = isSmartSearchEnabled ? smartSearchState?.filter_object?.dateInfo : dateSelected;

  const { dayPartingState } = useDayPartingContext();
  const isDayScheduleApplied = dayPartingState?.dateInfo && Object.keys(dayPartingState?.dateInfo).length > 0 ? true : false;
  defaultDateInfo = isDayScheduleApplied ? dayPartingState?.dateInfo : defaultDateInfo;

  if (isCalendarOnly === 1 && defaultCalendar == 1) {
    return (
      <>
        <Whisper preventOverflow placement={placement ? placement : 'bottomEnd'} speaker={DatePicker} open={showDatepicker} trigger="none">
          <div
            className={`bg-white p-3 text-dark d-flex align-items-center f-w-500 f-12 cursor-pointer rounded-8 calender_style ${
              isSmartSearchEnabled || isDayScheduleApplied ? 'disabled' : ''
            }`}
            onClick={() => {
              if (isSmartSearchEnabled) return false;
              setSelectedDateRange(
                preDateSelected && Object.keys(preDateSelected).length > 0
                  ? [preDateSelected]
                  : [
                      {
                        startDate: subDays(new Date(), 30),
                        endDate: subDays(new Date(), 1),
                        pastStartDate: subDays(new Date(), 60),
                        pastEndDate: subDays(new Date(), 31),
                        key: 'Last 30 Days'
                      }
                    ]
              );
              setTempDateRange(
                preDateSelected && Object.keys(preDateSelected).length > 0
                  ? {
                      ...preDateSelected,
                      dates: [],
                      compareDates: []
                    }
                  : {
                      startDate: subDays(new Date(), 30),
                      endDate: subDays(new Date(), 1),
                      pastStartDate: subDays(new Date(), 60),
                      pastEndDate: subDays(new Date(), 31),
                      dates: [],
                      compareDates: [],
                      key: 'Last 30 Days'
                    }
              );
              setDoCompare(
                payloadState.dateRange && Object.keys(payloadState.dateRange).length > 0 && 'compare' in payloadState.dateRange
                  ? payloadState.dateRange?.compare
                  : false
              );
              setShowdatepicker(true);
            }}
          >
            <img src={Calender} alt="date_picker" className="wid-20 mr-2" />
            <div className="d-flex flex-column gap-4 l-15">
              <div className="d-flex align-items-center">
                <p className="f-w-600 mr-1">
                  {defaultDateInfo.key != '' && defaultDateInfo.key != 'selection' ? defaultDateInfo.key : 'Custom'} :{' '}
                </p>
                {format(defaultDateInfo.startDate, 'dd MMM, yyyy')}{' '}
                {format(defaultDateInfo.startDate, 'dd MMM, yyyy') !== format(defaultDateInfo.endDate, 'dd MMM, yyyy')
                  ? ` - ${format(defaultDateInfo.endDate, 'dd MMM, yyyy')}`
                  : ``}
              </div>
              {doCompare || defaultDateInfo?.compare == 1 || isDayScheduleApplied ? (
                <div className="f-10 f-w-400">
                  Compared to&nbsp;:&nbsp;{format(defaultDateInfo.pastStartDate, 'dd MMM, yyyy')}
                  {format(defaultDateInfo.pastStartDate, 'dd MMM, yyyy') !== format(defaultDateInfo.pastEndDate, 'dd MMM, yyyy')
                    ? ' - ' + format(defaultDateInfo.pastEndDate, 'dd MMM, yyyy')
                    : ''}
                </div>
              ) : null}
            </div>
            <CollapseArrowIcon />
          </div>
        </Whisper>
      </>
    );
  }

  if (isCustomCalender === 1) {
    return (
      <Whisper preventOverflow placement="auto" speaker={DatePicker} open={showDatepicker} trigger="none">
        <div onClick={() => setShowdatepicker(true)}>{children}</div>
      </Whisper>
    );
  }

  if (masterSearchCalendar == 1) {
    return (
      <>
        {/* CALENDAR MODAL */}
        <div className={`date-picker-modal-one theme_date_pcker z-2300 ${selectedDateRange.length > 1 ? 'hide_range' : ''} bg-white`}>
          <section className="px-2 border-bottom pb-2">
            <DateRangePicker
              // moveRangeOnFirstSelection={true}
              // retainEndDateOnFirstSelection={true}
              editableDateInputs={true}
              calendarFocus={isThisMonth(tempDateRange.startDate) == true ? 'backwards' : 'forwards'}
              ranges={[...selectedDateRange].concat([
                ...(doCompare == 1 && selectedDateRange?.length > 0
                  ? [
                      {
                        startDate: dateFormat(selectedDateRange[0].pastStartDate),
                        endDate: dateFormat(selectedDateRange[0].pastEndDate),
                        color: '#ACACAC'
                      }
                    ]
                  : [])
              ])}
              months={isDirectType === 'between' ? 2 : 1}
              // shownDate={new Date(lastMonth)}
              // initialFocusedRange={{
              //   startDate: subMonths(new Date(), 1),
              //   endDate: new Date(),
              //   key:'Default'
              // }}
              shouldDisableDate={date => isAfter(date, new Date())}
              onChange={(item) => {
                console.log('CHANGE', item);
                if (item.range1) {
                  console.log('item.range1', item);
                  onEmitDateRange({
                    ...(isDirectType == 'between'
                      ? {
                          startDate: item.range1?.startDate,
                          endDate: item.range1?.endDate,
                          key: 'Custom'
                        }
                      : {
                          startDate: item.range1?.startDate,
                          endDate: item.range1?.endDate,
                          key: 'Custom'
                        })
                  });
                } else if (item.selection) {
                  onEmitDateRange({
                    ...(isDirectType == 'between'
                      ? {
                          startDate: item.selection?.startDate,
                          endDate: item.selection?.endDate
                        }
                      : {
                          startDate: item.selection?.startDate,
                          endDate: item.selection?.startDate
                        })
                  });
                } else if (!item.range) {
                  const dateInfo = { ...Object.values(item)[0], key: 'Custom' };
                  onEmitDateRange({
                    ...(isDirectType == 'between'
                      ? {
                          startDate: dateInfo?.startDate,
                          endDate: dateInfo?.endDate
                        }
                      : {
                          startDate: dateInfo?.startDate,
                          endDate: dateInfo?.startDate
                        })
                  });
                }
              }}
              direction="horizontal"
              maxDate={tillToday ? addDays(new Date(), 0) : addDays(new Date(), -1)}
              staticRanges={[]}
            />
          </section>
        </div>
        {/* CALENDAR MODAL */}
      </>
    );
  }

  return (
    <div>
      {/* <h2 className="f-24 f-w-700 text-dark">{reportTitle}</h2> */}
      <section className="position-relative">
        <section className={`cal_tar d-flex align-items-center position-cng ${classes}`}>
          {defaultCalendar == 1 ? (
            <Whisper
              preventOverflow
              placement={placement ? placement : 'bottomEnd'}
              speaker={DatePicker}
              open={showDatepicker}
              trigger="none"
            >
              <div
                className={`bg-white p-3 text-dark d-flex align-items-center f-w-500 f-15 cursor-pointer rounded-8 calender_style ${
                  isSmartSearchEnabled ? 'disabled' : ''
                }`}
                onClick={() => {
                  if (isSmartSearchEnabled) return false;
                  setShowdatepicker(true);
                }}
              >
                <img src={Calender} alt="date_picker" className="wid-20 mr-2" />
                <div className="d-flex flex-column gap-10">
                  <div className="d-flex align-items-center">
                    <p className="f-w-600 mr-1">
                      {dateSelected.key != '' && dateSelected.key != 'selection' ? dateSelected.key : 'Custom'} :{' '}
                    </p>
                    {format(dateSelected.startDate, 'dd MMM, yyyy')}{' '}
                    {format(dateSelected.startDate, 'dd MMM, yyyy') !== format(dateSelected.endDate, 'dd MMM, yyyy')
                      ? ` - ${format(dateSelected.endDate, 'dd MMM, yyyy')}`
                      : ``}
                  </div>
                  {/* {compare ? <div className='f-10 f-w-400'>Compared&nbsp;:&nbsp; Jan 07 - Jan 13, 2024</div> : null} */}
                </div>
                <CollapseArrowIcon />
              </div>
            </Whisper>
          ) : null}
          {showDatepicker && <div className="position-fixed inset-0 z-1500" onClick={() => setShowdatepicker(false)}></div>}
          {/* {showDatepicker && (
            
          )} */}
        </section>
      </section>
    </div>
  );
}
