import React, { Component, useState, useEffect, useRef } from 'react';
import CalendarDays from './calendar-days';
import './calendar.scss'

export default class PathCalendar extends Component {
  constructor(props) {
    super(props); 

    this.weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    this.months = ['January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'];

    this.state = {
      currentDay: new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 20) % 28)),
      endDay: new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 14) % 28))
    }
    this.biWeek = false;
    this.initialClick = true;
    this.backup = props?.history ? global.structuredClone(props.history) : {
      currentDay: new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 20) % 28)),
      endDay: new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 14) % 28))
    }
    this.changedDatesss= props?.history ? global.structuredClone(props.history) :{
      currentDay: new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 21) % 28)),
      endDay: new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 15) % 28))
    };
    this.currentDateUpdated = true;
  }

 
  changeCurrentDay = (day) => {
    if(this.currentDateUpdated) {
      this.changedDatesss['currentDay'] = day.date;
      this.changedDatesss['endDay'] = day.date;
      this.currentDateUpdated = false;
    }
    else {
      this.changedDatesss['endDay'] = new Date(new Date(day.date).setDate(new Date(day.date).getDate()));
      this.currentDateUpdated = true;
    }
    this.setState(this.state);
    this.props.onDataReceive(global.structuredClone(this.changedDatesss))
  }

  nextDay = () => {
    let month = new Date(this.state.currentDay.setMonth(this.state.currentDay.getMonth() + 1));
    if(month.getMonth() === this.backup.currentDay.getMonth()) {
      this.setState(global.structuredClone(this.backup))
    }
    else {
      this.setState({ currentDay: month, endDay: null });
    }
  }

  previousDay = () => {
    let month = new Date(this.state.currentDay.setMonth(this.state.currentDay.getMonth() - 1));
    if(month.getMonth() === this.backup.currentDay.getMonth()) {
      this.setState(global.structuredClone(this.backup))
    }
    else {
      this.setState({ currentDay: month, endDay: null });
    }
  }

  render() {
    return (
      <div className="calendar">
        <div className="calendar-header">
          
          <div className="tools">
            <button onClick={this.previousDay}>
              <span className="material-icons">
                arrow_back
                </span>
            </button>
            <p style={{fontWeight:'bold', width:'150px', textAlign:'center'}}>{this.months[this.state.currentDay.getMonth()]} {this.state.currentDay.getFullYear()}</p>
            <button onClick={this.nextDay}>
              <span className="material-icons">
                arrow_forward
                </span>
            </button>
          </div>
        </div>
        <div className="calendar-body">
          <div className="table-header">
            {
              this.weekdays.map((weekday) => {
                return <div className="weekday"><p>{weekday}</p></div>
              })
            }
          </div>
          <CalendarDays day={this.state.currentDay} changedDates={this.changedDatesss} end={this.state.endDay} biWeek={this.biWeek} changeCurrentDay={this.changeCurrentDay} />
        </div>
      </div>
    )
  }
}