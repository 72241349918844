function CalendarDays(props) {
    const firstDayOfMonth = new Date(props.day.getFullYear(), props.day.getMonth(), 1);
    const weekdayOfFirstDay = firstDayOfMonth.getDay();
    let currentDays = [];

    console.log(props.changedDates)

    const weekdayss = [1,2,3,4,5,6,0]

    for(let i=0; i< weekdayss.indexOf(weekdayOfFirstDay); i++)
    {
      currentDays.push({})
    }
  
    for (let day = 0; day < new Date(firstDayOfMonth.getFullYear(), firstDayOfMonth.getMonth()+1, 0).getDate(); day++) {

      if(day !== 0 ){
        firstDayOfMonth.setDate(firstDayOfMonth.getDate()+1);
      }
      let calendarDay = {
        currentMonth: (firstDayOfMonth.getMonth() === props.day.getMonth()),
        date: (new Date(firstDayOfMonth)),
        month: firstDayOfMonth.getMonth(),
        number: firstDayOfMonth.getDate(),
        selected: props.changedDates.currentDay || props.changedDates.endDay ? props.changedDates.currentDay.toISOString().split('T')[0] <= new Date(firstDayOfMonth).toISOString().split('T')[0] && props.changedDates.endDay.toISOString().split('T')[0] >= new Date(firstDayOfMonth).toISOString().split('T')[0] ? true : false : false ,
        year: firstDayOfMonth.getFullYear(),
        disabled: firstDayOfMonth.getDay() !== 1 && firstDayOfMonth.getDay() !== 0  ?  true: (new Date().toISOString().split("T")[0] > new Date(firstDayOfMonth).toISOString().split("T")[0]) ? new Date(firstDayOfMonth).toISOString() > new Date('2024-07-28').toISOString() ? checkWeeklybiweekly(firstDayOfMonth) ? false : true : true  : true
      }
  
      currentDays.push(calendarDay);
    }
    
  //(day.selected ? " selected" : "" + 
    return (
      <div className="table-content">
        {
          currentDays.map((day) => {
            return (
              <div style={!day.number ? {pointerEvents:'none'} :{}} className={"calendar-day" + (day.currentMonth ? " current" : "") + (day.selected ? " selected no-disabled" : "") + (day.disabled ? " disabled":" ")}
                    onClick={() => props.changeCurrentDay(day)}>
                <p>{day.number}</p>
              </div>
            )
          })
        }
      </div>
    )
  }

  function checkWeeklybiweekly (date) {
    let biWeekarr = [];
    if(new Date(date).toISOString().split('T')[0] >= new Date('2024-07-28').toISOString().split('T')[0])
    {
      for(let i=0; i<300; i ++) {
        if(i === 0) {
          biWeekarr.push(new Date('2024-07-29').toISOString().split('T')[0]);
        }
        else {
          if((new Date(date).getMonth() === new Date().getMonth()) || (new Date(date).getMonth() === new Date().getMonth()-1)) {
            biWeekarr.push(new Date(new Date(biWeekarr[i-1]).setDate(new Date(biWeekarr[i-1]).getDate() + 7)).toISOString().split('T')[0]);
            biWeekarr.push(new Date(new Date(biWeekarr[i-1]).setDate(new Date(biWeekarr[i-1]).getDate() + 6)).toISOString().split('T')[0]);
          }
          else {
            biWeekarr.push(new Date(new Date(biWeekarr[i-1]).setDate(new Date(biWeekarr[i-1]).getDate() + 14)).toISOString().split('T')[0]);
            biWeekarr.push(new Date(new Date(biWeekarr[i-1]).setDate(new Date(biWeekarr[i-1]).getDate() + 13)).toISOString().split('T')[0]);
          }
        }
      }
    }
    else {
      if(new Date(date).toISOString().split('T')[0] === new Date('2024-07-29').toISOString().split('T')[0])
        {
          return true;
        }
    }
    return biWeekarr.includes(new Date(new Date(date).setDate(new Date(date).getDate() + 1)).toISOString().split('T')[0])
  }
  
  export default CalendarDays;