import React, { useContext,createContext,useState, useRef, useEffect } from 'react';
import { ListGroup, Dropdown, Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ThemeSwitcher from 'src/components/Actions/themeToggle';
import Styles from 'src/components/themeStyle';
import ChatList from './ChatList';
import { ConfigContext } from '../../../../contexts/ConfigContext';
import useAuth from '../../../../hooks/useAuth';
import { useLocation } from 'react-router-dom';
import SettingsIcon from 'src/assets/images/common/settings.svg';
import Spinner from 'react-bootstrap/Spinner';
import PathCalendar from '../../../../views/pages/amc-insights/components/path-conversion-charts/amc-path-calendar';
import Row from 'react-bootstrap/Row';
import { Tooltip } from '@mui/material';
import infoIcon from 'src/assets/images/common/info.svg';

// import 'react-date-range/dist/styles.css';
// import 'react-date-range/dist/theme/default.css';
// import { DateRangePicker } from 'react-date-range';
// import { Popover, Whisper } from 'rsuite';
// import { addDays } from 'date-fns';
import queryString from 'query-string';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useHistory } from 'react-router-dom';

import { Maximize, Bell, User, LogOut, UserCheck, FileText, Minimize, Settings, CloudDrizzle, Crosshair } from 'react-feather';
import { ThemeContext, ThemeProvider } from 'src/providers/themeProvider';
import Search from 'src/components/Form/search';
import { SearchFilter } from 'src/helper/search';
import ReactGA from 'react-ga4';
import { sanitizeValue, generateUTCtimestamp } from 'src/utils/custom-formatters';
import FlipkartProfiles from './FlipkartProfiles';

import { ReactCountryDropdown } from 'react-country-dropdown';
import 'react-country-dropdown/dist/index.css';
import { CustomCountryDropdown } from 'src/components/CountryFlagSelect';
import { useCookies } from 'react-cookie';
import BugModal from 'src/views/pages/targetting/bugModal';
import { MAIN_URL } from 'src/config/constant';
import { yetToOnboarded } from 'src/utils/utils';
import { useCommonContext } from 'src/contexts/CommonContext';
import Calendar from 'src/views/pages/targetting/calendar';
import { addDays, subDays } from 'date-fns';
import { usePayloadContext } from 'src/contexts/PayloadContext';
import TargetingSettings from './settings-v2';
import ClearCrossFilter from './Clear';
import { replaceString } from 'src/api/demo';
import useApi from 'src/hooks/useApi';
import services from 'src/api/service';
import { getUserIdentity } from 'src/api/identity';
import GlobalFilter from './globalFilter/index';
import MyntraProfiles from './MyntraProfiles';
import BlinkitProfiles from './BlinkitProfiles';
import InstamartProfiles from './InstamartProfiles';

const StyleTag = () => {
  const [themeMode, setTheme] = useContext(ThemeContext);
  console.log('themeMode', themeMode.theme);
  return (
    <div>
      <style>{Styles(themeMode.theme)}</style>
    </div>
  );
};

// const defaultActiveData =
export const DataPathContext = createContext();

const NavRight = ({ activeProfiles, isFullScreen, enableFullscreen }) => {
  const { payloadState, payloadDispatch } = usePayloadContext();

  const isFlipKart = window.location.href.indexOf('/flipkart') > -1;
  const isInstamart = window.location.href.indexOf('/instamart') > -1;
  const { commonState, commonDispatch } = useCommonContext() 
  const location = useLocation();
  console.log('NavRightactiveProfiles', activeProfiles);
  let ParseData = JSON.parse(localStorage.getItem('User'));
  const localSelectedProfileId = JSON.parse(localStorage.getItem('selectedProfileId'));
  const selectedLocalCountry = JSON.parse(localStorage.getItem('selectedCountry'));

  const doLogoutSettings = useApi(services.postService);
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const handleCloseLogoutModal = () => setShowLogoutModal(false);

  useEffect(() => {
    commonDispatch({
      type: 'UPDATE_USER',
      payload: {
        fullName: ParseData?.fullName || '',
        firstName: ParseData?.firstName || '',
        phoneNumber: ParseData?.phoneNumber || '',
        email: ParseData?.email || '',
        token: ParseData?.token || '',
        profileStatus: ParseData?.profileStatus || false
      }
    });
  }, []);

  const [showDropdown, setShowDropdown] = useState(false);
  const [showProfDropdown, setShowProfDropdown] = useState(false);
  const [showAccDropdown, setShowAccDropdown] = useState(false);
  const [showFlagDropdown, setShowFlagDropdown] = useState(false);
  const [profileResponse, setProfileResponse] = useState({ data: [activeProfiles], filteredData: [] });

  const configContext = useContext(ConfigContext);
  const { logout } = useAuth();
  const { rtlLayout } = configContext.state;
  const queryParams = window.location.href.substring(window.location.href.lastIndexOf('/'));
  const [listOpen, setListOpen] = useState(false);
  const [activeProfilesAMS, setAMSactiveProfiles] = useState({ data: [], filteredData: [] });
  const [valueSearch, setValueSearch] = useState('');
  const [defaultActive, setDefaultActive] = useState(localSelectedProfileId ? [localSelectedProfileId] : activeProfiles?.slice(0, 1));
  const [countrySelected, setCountrySelected] = useState(selectedLocalCountry ? selectedLocalCountry : { code: 'IN' });
  const [receiveData, setReceiveData] = useState();
  const [calendarToggle, setCalendarToggle] = useState(false);
  const dateRangeConst = useRef(JSON.stringify(payloadState?.dateRange));
  let history = useHistory();

  const setDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const setProfDropdown = () => {
    setShowProfDropdown(!showProfDropdown);
  };

  const setAccDropdown = () => {
    setShowAccDropdown(!showAccDropdown);
    console.log({ localSelectedProfileId, showAccDropdown });
    if (!showAccDropdown) {
      console.log({ localSelectedProfileId, showAccDropdown });
      setCountrySelected({ code: localSelectedProfileId.countryCode });
    }
  };

  const setFlagDropdown = () => {
    setShowFlagDropdown(!showFlagDropdown);
  };

  const handleSearchInput = async (e) => {
    const keyword = e.target.value;
    console.log('keyword', keyword);
    const { data, filteredData } = await SearchFilter(profileResponse.data, keyword);

    if (keyword !== '') {
      setProfileResponse({ data, filteredData });
      console.log('KEYWORD_____1', keyword);
    } else {
      setProfileResponse({ data, filteredData });
    }
    setValueSearch(keyword);
  };

  const filteredProfile = () => {
    console.log('getProfileRows', profileResponse.filteredData.length ? profileResponse.filteredData : profileResponse.data);
    return profileResponse.filteredData.length ? profileResponse.filteredData : profileResponse.data;
  };
  const handleDataReceive = (receivedData) => {
    setReceiveData(receivedData); // Update the state with data received from ClassComponent
  };

  useEffect(() => {
    if (showAccDropdown) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [showAccDropdown]);

  const [cookies, setCookie, removeCookie] = useCookies();

  useEffect(() => {
    console.log('queryParams222', queryParams);

    if (String(queryParams) === '/targeting-summary') {
      console.log('queryParams---->', queryParams);

      history.push(MAIN_URL);
    }
    if (activeProfiles && Array.isArray(activeProfiles) && activeProfiles?.length) {
      console.log('defaultActive-----', activeProfiles);
      setAMSactiveProfiles({ data: activeProfiles, filteredData: [] });
      setDefaultActive(
        localSelectedProfileId && Object.keys(localSelectedProfileId).length > 0 ? [localSelectedProfileId] : activeProfiles?.slice(0, 1)
      );
      // if (!localSelectedProfileId || (localSelectedProfileId && Object.keys(localSelectedProfileId).length === 0)) {
      //   let defaultSelectedId = activeProfiles?.slice(0, 1);
      //   console.log('selectedProfileId', defaultSelectedId);
      //   localStorage.setItem('selectedProfileId', JSON.stringify(defaultSelectedId[0]));
      //   setCookie('last-profile', defaultSelectedId[0])
      // }
    }
  }, [activeProfiles]);

  //   useEffect(()=>{
  //     if(activeProfilesAMS?.length){
  //     setDefaultActive(activeProfilesAMS?.slice(0, 1))
  // }
  // },[activeProfilesAMS])

  const handleSelect = (selectedItem) => {
    setDefaultActive([selectedItem]);
    localStorage.setItem('selectedProfileId', JSON.stringify(selectedItem));
    setCookie('last-profile', selectedItem);
    document.getElementById('fullPageLoader').style.visibility = 'visible';
    setTimeout(() => {
      if (window.location.href.indexOf('plan-expired') > -1) {
        window.location.href = '/amazon/targeting';
      } else {
        window.location.reload();
      }
    }, 100);
    window.location.reload();
    setShowAccDropdown(!showAccDropdown);
  };

  // console.log("defaultActive", defaultActive)
  // useEffect(()=>{
  //   if(localSelectedProfileId)
  //   console.log("selectedProfileId")
  //   localStorage.setItem('selectedProfileId', JSON.stringify(defaultActive))
  // },[defaultActive])

  // const [datePickerOpen, setDatePickerOpen] = useState(false)

  // const [state, setState] = useState([
  //   {
  //     startDate: new Date(),
  //     endDate: addDays(new Date(), 7),
  //     key: 'selection'
  //   }
  // ]);

  // function DatePicker() {

  //   if (datePickerOpen === false) {
  //     setDatePickerOpen(true)

  //   } else if (datePickerOpen === true) {
  //     setDatePickerOpen(false)
  //   }
  // }

  // const speaker = (
  //   <Popover style={{ zIndex: "10000" }}>
  //     <div>

  //       <DateRangePicker
  //         onChange={item => setState([item.selection])}
  //         showSelectionPreview={true}
  //         moveRangeOnFirstSelection={false}
  //         months={2}
  //         ranges={state}
  //         direction="horizontal"
  //       ></DateRangePicker>
  //       {console.log("datepicker") }
  //       <div className="text-right position-relative rdr-buttons-position mt-2 mr-3">
  //         <button
  //           className="btn btn-transparent text-danger rounded-0 px-4" onClick={DatePicker}
  //         >
  //           Close
  //         </button>
  //       </div>
  //     </div>

  //   </Popover>
  // );

  const searchInputHandle = async (e) => {
    const keyword = e.target.value;
    console.log('keyword', keyword);
    const { data, filteredData } = await SearchFilter(activeProfilesAMS.data, keyword);

    if (keyword !== '') {
      setAMSactiveProfiles({ data, filteredData });
    } else {
      setAMSactiveProfiles({ data, filteredData });
    }
    setValueSearch(keyword);
  };

  const getTableRows = () => {
    console.log('getTAbleRows', activeProfilesAMS.filteredData);
    const getProfile = JSON.parse(localStorage.getItem('selectedProfileId'));
    if (valueSearch !== '') {
      const defaultProfiles = activeProfilesAMS.filteredData.filter((dataItem) => dataItem.profileId == getProfile?.profileId);
      const nonDefaultProfiles = activeProfilesAMS.filteredData.filter((dataItem) => dataItem.profileId != getProfile?.profileId);
      return [...defaultProfiles, ...nonDefaultProfiles];
    } else {
      const defaultProfiles = activeProfilesAMS.data.filter((dataItem) => dataItem.profileId == getProfile?.profileId);
      const nonDefaultProfiles = activeProfilesAMS.data.filter((dataItem) => dataItem.profileId != getProfile?.profileId);
      return [...defaultProfiles, ...nonDefaultProfiles];
    }
  };

  // console.log("getTableRows", getTableRows(), activeProfilesAMS.data)

  const handleLogoutHandle = () => {
    setShowLogoutModal(true);
    //sweetAlertHandler({ type: 'alert', text: 'Are you sure you want to logout?', showConfirmButton: true, CancelButton: true });
  };

  // const handleLogout = async () => {
  //   try {
  //     doLogoutSettings.request({
  //       url: '/auth/logout',
  //       nodeAPI: 1,
  //       payload: {},
  //     });
  //     await logout();
  //     ReactGA.gtag('event', 'Logged Out', {
  //       event_category: 'User',
  //       event_label: ParseData && Object.keys(ParseData).length > 0 && 'email' in ParseData ? ParseData.email : '',
  //       value: generateUTCtimestamp()
  //     });
  //     const profileName = `/login?userId=${ParseData.email}&type=stop-viewing&utcTime=${generateUTCtimestamp()}&userName=${[
  //       ParseData.firstName,
  //       ParseData.lastName
  //     ]
  //       .filter((value) => value != '')
  //       .join(' ')}`;
  //     ReactGA.send({ hitType: 'pageview', page: location.pathname, title: profileName });
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  const handleLogout = async () => {
    try {
      await doLogoutSettings.request({
        url: '/auth/logout',
        nodeAPI: 1,
        payload: {},
      });
      ReactGA.gtag('event', 'Logged Out', {
        event_category: 'User',
        event_label: ParseData && Object.keys(ParseData).length > 0 && 'email' in ParseData ? ParseData.email : '',
        value: generateUTCtimestamp()
      });
      const profileName = `/login?userId=${ParseData.email}&type=stop-viewing&utcTime=${generateUTCtimestamp()}&userName=${[
        ParseData.firstName,
        ParseData.lastName
      ]
        .filter((value) => value != '')
        .join(' ')}`;
      ReactGA.send({ hitType: 'pageview', page: location.pathname, title: profileName });
      await logout();
      history.push('/');
    } catch (err) {
      console.error(err);
    }
  };
  

  const sweetAlertHandler = (alert) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: alert.text,
      type: alert.type,
      timer: alert.timer,
      showConfirmButton: alert.showConfirmButton,
      didClose: alert.action
        ? alert.action
        : () => {
            console.log('Actioned');
          },
      allowOutsideClick: false,
      showCancelButton: alert.CancelButton
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('isConfirmed');
        doLogoutSettings.request({
          url: '/auth/logout',
          nodeAPI: 1,
          payload: {}
        });
        handleLogout();
        history.push('/');
        ReactGA.gtag('event', 'Logged Out', {
          event_category: 'User',
          event_label: ParseData && Object.keys(ParseData).length > 0 && 'email' in ParseData ? ParseData.email : '',
          value: generateUTCtimestamp()
        });
        const profileName = `/login?userId=${ParseData.email}&type=stop-viewing&utcTime=${generateUTCtimestamp()}&userName=${[
          ParseData.firstName,
          ParseData.lastName
        ]
          .filter((value) => value != '')
          .join(' ')}`;
        ReactGA.send({ hitType: 'pageview', page: location.pathname, title: profileName });
        // ReactGA.event({
        //   category: 'User',
        //   action: 'Logout',
        //   label: ParseData && Object.keys(ParseData).length > 0 && 'firstName' in ParseData ? `${ParseData.firstName} is Logging out` : "User is Logging Out",
        //   value: ParseData && Object.keys(ParseData).length > 0 && 'email' in ParseData ? ParseData.email : ""
        // });
      } else if (result.isDenied) {
        // Swal.fire('Changes are not saved', '', 'info')
      }
    });
  };

  const handleSelectCountry = (country) => {
    setCountrySelected(country);
    localStorage.setItem('selectedCountry', JSON.stringify(country));
  };

  useEffect(() => {
    const handleWindowFocus = () => {
      // Get the value from localStorage
      const localProfile = JSON.parse(localStorage.getItem('selectedProfileId'));
      // Compare localStorage value with state value
      defaultActive.forEach((element) => {
        if (localProfile && localProfile?.profileId != element?.profileId) {
          console.log('NotMatched', { localProfile, defaultActive });
          // setCookie('last-profile', {});
          // alert(JSON.stringify({localProfile, defaultActive}))
          // localStorage.setItem('selectedProfileId', JSON.stringify({}))
          window.location.reload();
        } else {
          console.log('handleWindowFocus Matched!, Good to Go');
          console.log('Matched', { localProfile, defaultActive });
        }
      });
    };
    if (!isFlipKart) {
      window.addEventListener('focus', handleWindowFocus);
    }
    return () => {
      if (!isFlipKart) {
        window.removeEventListener('focus', handleWindowFocus);
      }
    };
  }, [defaultActive]);


  useEffect(() => {
    const handleWindowFocus = () => {
      // Get the value from localStorage
      const localProfile = JSON.parse(localStorage.getItem('selectedProfileId'));
      // Compare localStorage value with state value
      defaultActive.forEach(element => {
        if (localProfile && localProfile?.profileId != element?.profileId) {
          console.log("NotMatched", {localProfile, defaultActive})
          // setCookie('last-profile', {});
          // alert(JSON.stringify({localProfile, defaultActive}))
          // localStorage.setItem('selectedProfileId', JSON.stringify({}))
          window.location.reload()
        } else {
          console.log('handleWindowFocus Matched!, Good to Go');
          console.log("Matched", {localProfile, defaultActive})
        }
      });
    }
    if(!isInstamart) {
      window.addEventListener('focus', handleWindowFocus);
    }
    return () => {
      if(!isInstamart) {
        window.removeEventListener('focus', handleWindowFocus);
      }
    };
  }, [defaultActive]);

  

  const isCategorizationShow = window.location.href.indexOf('/flipkart') > -1 || window.location.href.indexOf('/amazon') > -1 || window.location.href.indexOf('/amazon-master-overview') > -1 || window.location.href.indexOf('/myntra') || window.location.href.indexOf('/blinkit') || window.location.href.indexOf('/instamart')  > -1

  const [selectedDateRange, setSelectedDateRange] = useState({});

  const [datePayload, setDatePayload] = useState(payloadState.dateRange);

  useEffect(() => {
    setDatePayload(payloadState.dateRange);
  }, [payloadState.dateRange]);

  const calendarApply = () => {
    payloadDispatch({type: 'UPDATE_CALENDAR_DATES', payload: {receiveData }});
    setCalendarToggle(false);
  }

  return (
    <React.Fragment>
      {/* <ThemeProvider> */}
      {/* <div style={{ }}>
      <Whisper
        preventOverflow
        placement="auto"
        speaker={speaker}
        open={datePickerOpen}
        trigger="none"
      >
        <input
          id="formControlsTextB"
          type="text"
          label="Text"
          // value={}
          onClick={DatePicker}
        />
      </Whisper>
      </div> */}
      <ListGroup as="ul" bsPrefix=" " className="navbar-nav ml-auto" id="navbar-right">
      {window.location.href.indexOf('/amazon-master-overview') > -1 || window.location.href.indexOf('/amazon-snapshot') > -1 || window.location.href.indexOf('/amazon/targeting') > -1 ? <ListGroup.Item as="li" bsPrefix=" ">
        <GlobalFilter />
      </ListGroup.Item> : null}
      {window.location.href.indexOf('summary/flipkart') > -1 || window.location.href.indexOf('summary/instamart') > -1 ||  window.location.href.indexOf('summary/myntra') > -1 || window.location.href.indexOf('summary/blinkit') > -1 || window.location.href.indexOf('amazon/targeting') > -1 ?
        <ClearCrossFilter /> : null}
      {window.location.href.indexOf('summary/flipkart') > -1 || window.location.href.indexOf('summary/instamart') > -1 || window.location.href.indexOf('summary/myntra') > -1 || window.location.href.indexOf('summary/blinkit') > -1 || window.location.href.indexOf('amazon/targeting') > -1 ? <ListGroup.Item as="li" bsPrefix=" ">
        <TargetingSettings />
      </ListGroup.Item> : null}
      {window.location.href.indexOf('categorization') == -1 && (window.location.href.indexOf('master-overview') > -1 || window.location.href.indexOf('snapshot') > -1 || window.location.href.indexOf('amazon') > -1 || window.location.href.indexOf('flipkart') > -1 || window.location.href.indexOf('myntra') > -1 || window.location.href.indexOf('blinkit') > -1 || window.location.href.indexOf('activity') > -1) || window.location.href.indexOf('instamart') > -1 ? 
      <ListGroup.Item as="li" bsPrefix=" " className='nav_datepicker'>
       {window.location.pathname !== '/amazon-path-to-conversion' && window.location.pathname !== '/amazon-insights' && <Calendar
          isCalendarOnly={1}
          // isSnapshot={true}
          availableColumns={[]}
          // compare={1}
          classes="no_absol f-10"
          preDateSelected={datePayload}
          onEmitDateRange={(ranges, compare, currentDateRangeFormat) => {
            payloadDispatch({type: 'UPDATE_DATE_RANGE', payload: { ...ranges, compare, currentDateRangeFormat }})
          }}
          defaultCalendar={1}
        />}
        { (window.location.pathname === '/amazon-path-to-conversion') &&
           <>
           <DataPathContext.Provider value={{ receiveData, setReceiveData }}>
           <button className='btn path-btn' onClick={e=> setCalendarToggle(!calendarToggle)}><img src="/static/media/calender.4a8ef0a9.svg" alt="date_picker" class="wid-20 mr-2" /> {receiveData ? (receiveData.currentDay.toDateString() + ' - ' + receiveData.endDay.toDateString()) : (new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 20) % 28)).toDateString() + ' - ' + new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 14) % 28)).toDateString())}</button>
              {calendarToggle && <div className='path-calendar-area'>
              <PathCalendar onDataReceive={handleDataReceive} history={receiveData}/>
              <Row style={{ margin: '10px 25px', gap: '10px', display: 'flex', justifyContent: 'flex-start' }}>
              <p className='data-alert'> <Tooltip
                    title={`If alternate Mondays are enabled, biweekly data (fully attributed) will be available; otherwise, only weekly data (partial attributed) will be accessible`}
                    position="top"
                    trigger="mouseenter"
                    arrow={true}
                    interactive={true}
                    animation="shift"
                    animateFill={true}
                  >
                    <img src={infoIcon} alt="info" className="cursor-pointer wid-13 ml-2" />
                  </Tooltip> Data available from 29th July 2024 </p>

                <p className='path-note'>
                
                  {/* <span style={{fontWeight:'600'}}>Note: </span> <span></span> */}
                </p>
        </Row>
        <Row style={{ margin: '10px 25px', gap: '10px', display: 'flex', justifyContent: 'flex-end' }}>
        <button variant="contained" style={{ border:0 }} className="btn-cancel" fullWidth onClick={(e)=> setCalendarToggle(!calendarToggle)} sx={{ fontSize: '12px' }}>
            Cancel
          </button>
          <button variant="contained" style={{ border:0 }} className="btn-apply" fullWidth onClick={(e)=> calendarApply()} sx={{ fontSize: '12px' }}>
            Apply
          </button>
        </Row>
              </div>}
              </DataPathContext.Provider>
           </>
        }
      </ListGroup.Item> : null}
        {!window.location.href.includes('flipkart') &&
          !window.location.href.includes('instamart') &&
          !window.location.href.includes('myntra') &&
          !window.location.href.includes('blinkit') &&
          !window.location.href.includes('add-profile') &&
          !window.location.href.includes('profile') &&
          !window.location.href.includes('thankyou') &&
          !window.location.href.includes('addaccount') &&
          !window.location.href.includes('account-settings') &&
          !window.location.href.includes('payment') &&
          !window.location.href.includes('activity') &&
          activeProfiles &&
          activeProfiles?.length > 0 && (
            <ListGroup.Item as="li" bsPrefix=" " className="nav_acc_slct ch_toggle">
              <Dropdown alignRight={!rtlLayout} className="select-account" show={showAccDropdown} onToggle={setAccDropdown}>
                <Dropdown.Toggle as="li" id="dropdown-basic" onClick={setAccDropdown} className="cursor-pointer">
                  <div className="text-right d-flex flex-column l-base align-items-end pr-2 drop-crd">
                    <div className="f-w-600 f-14 d-flex align-items-center text-left">
                      {/* <img
                      src={`https://img.mobiscroll.com/demos/flags/${defaultActive?.map((select) => select?.countryCode)}.png`}
                      alt=""
                      className="wid-30 mr-2"
                    /> */}
                      <img
                        src={
                          defaultActive?.map((select) => select?.countryCode?.toLowerCase()) === 'uk'
                            ? 'https://flagpedia.net/data/flags/w1160/gb.webp'
                            : `https://flagcdn.com/${encodeURIComponent(
                                defaultActive?.map((select) => select?.countryCode?.toLowerCase())
                              )}.svg`
                        }
                        alt=""
                        className="country_flag_drop"
                      />
                      <div className="d-flex flex-column f-10 f-333843">
                        {defaultActive?.map((select) => replaceString(select?.accountInfo?.name))}
                        {defaultActive?.map((select, index) =>
                          select.accessLevel ? (
                            <span className="f-10 text-left w-100 f-w-500 spld-bder f-8F8F91" key={index}>
                              Role: <b className="f-w-600 f-10 f-333843">{select.accessLevel}</b>
                            </span>
                          ) : (
                            <span className="f-10 text-left w-100 f-w-500 spld-bder" key={index}>
                              Role: <b className="f-w-600 f-10">Admin</b>
                            </span>
                          )
                        )}
                      </div>
                    </div>
                    {/* <small className="f-10 bg-blur px-2 py-1 rounded-10 txt-theme">{defaultActive?.map((select) => select?.countryCode)}</small> */}
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu alignRight className="themebg notification notification-scroll">
                  <div className="noti-head">
                    <div className="countrySelection">
                      <h6 className="d-inline-block m-b-0 f-w-700 f-14 text-dark" style={{ float: 'right', marginTop: '10px' }}>
                        ACCOUNTS
                      </h6>
                      {showAccDropdown && countrySelected?.code ? (
                        <CustomCountryDropdown className="nav-item" onSelect={handleSelectCountry} countryCode={countrySelected?.code} />
                      ) : null}
                    </div>
                  </div>
                  <div className="px-3 pt-3 pb-1">
                    <Search searchValue={valueSearch} callBack={searchInputHandle} />
                  </div>
                  <PerfectScrollbar className="min-hight-150 max-height-300">
                    <ListGroup as="ul" bsPrefix=" " variant="flush" className="noti-body">
                      {(getTableRows()?.filter((rows) => rows.countryCode.toLowerCase() == countrySelected?.code.toLowerCase())?.length >
                        0 &&
                        getTableRows()
                          ?.filter((rows) => rows.countryCode.toLowerCase() == countrySelected?.code.toLowerCase())
                          ?.map((AMSprofiles, index) => (
                            <ListGroup.Item
                              as="li"
                              bsPrefix=" "
                              className={'n-title px-0 pt-2'}
                              key={index}
                              onClick={() => handleSelect(AMSprofiles)}
                            >
                              <Link
                                to="#"
                                className={`dropdown-item d-flex align-items-center justify-content-between ${
                                  defaultActive.map((data) => data.profileId).indexOf(AMSprofiles.profileId) > -1 ? 'active' : ''
                                }`}
                              >
                                <section className="d-flex align-items-center">
                                  {/* <img
                                src={`https://img.mobiscroll.com/demos/flags/${AMSprofiles?.countryCode}.png`}
                                alt=""
                                className="wid-30 hei-25 mr-4"
                              /> */}
                                  <img
                                    src={
                                      AMSprofiles?.countryCode?.toLowerCase() == 'uk'
                                        ? 'https://flagpedia.net/data/flags/w1160/gb.webp'
                                        : `https://flagcdn.com/${encodeURIComponent(AMSprofiles?.countryCode.toLowerCase())}.svg`
                                    }
                                    alt=""
                                    className="wid-30 hei-25 mr-4"
                                  />

                                  <div title={replaceString(AMSprofiles?.accountInfo?.name)}>
                                    <h4 className="mb-2 f-w-600 f-14 d-flex align-items-center">
                                      {' '}
                                      <span className="profile-name-truncate">{replaceString(AMSprofiles?.accountInfo?.name)} </span>
                                      <p
                                        className={`f-w-500 f-14 ml-2 mb-0 ${
                                          AMSprofiles.accountInfo.type === 'seller' ? 'txt-theme' : 'text-success'
                                        } `}
                                      >
                                        ({AMSprofiles.accountInfo.type})
                                      </p>
                                    </h4>

                                    {AMSprofiles.accessLevel ? (
                                      <p className="f-13 mb-0 f-w-500">
                                        Role: &nbsp; <b className="f-w-600 pr-1">{AMSprofiles.accessLevel}</b> |{' '}
                                        <a
                                          className="cursor-pointer profile_link f-12 pl-1"
                                          target="_blank"
                                          href={encodeURIComponent(AMSprofiles?.url)}
                                        >
                                          {AMSprofiles.accountInfo.id}
                                        </a>
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                    {process.env.REACT_APP_ADMIN_ACCESS_EMAIL.indexOf(commonState?.userInfo?.email) > -1 &&
                                    (('clientEmail' in AMSprofiles &&
                                      AMSprofiles.clientEmail != null &&
                                      AMSprofiles.clientEmail != '' &&
                                      AMSprofiles.clientEmail != commonState?.userInfo?.email) ||
                                      ('client_email' in AMSprofiles &&
                                        AMSprofiles.client_email != null &&
                                        AMSprofiles.client_email != '' &&
                                        AMSprofiles.client_email != commonState?.userInfo?.email)) ? (
                                      <p
                                        className="f-13 mb-0 f-w-500 profile-truncate"
                                        title={AMSprofiles.clientEmail ? AMSprofiles.clientEmail : AMSprofiles.client_email}
                                      >
                                        Owned By:{' '}
                                        <b className="f-w-600 pr-1">
                                          {AMSprofiles.clientEmail ? AMSprofiles.clientEmail : AMSprofiles.client_email}
                                        </b>
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                    {/* <p className="f-13 mb-0 f-w-500">{AMSprofiles.profileId}</p> */}
                                    {/* <p className="f-12 text-secondary f-w-500 d-flex align-items-center"> */}
                                  </div>
                                </section>
                              </Link>
                            </ListGroup.Item>
                          ))) ||
                        (getTableRows()?.filter((rows) => rows.countryCode.toLowerCase() == countrySelected?.code.toLowerCase())?.length ===
                          0 && (
                          <div className="hei-300 w-100 d-flex align-items-center justify-content-center f-w-700 txt-danger">
                            {' '}
                            No Profiles Found
                          </div>
                        ))}
                    </ListGroup>
                  </PerfectScrollbar>
                  <div className="noti-footer py-2 text-left">
                    <Link to="/profile" className="mb-1">
                      Manage Your Accounts
                    </Link>
                    <br />
                    <Link to="/addaccount">Add a new account</Link>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </ListGroup.Item>
          )}

        {window.location.href.includes('flipkart') ? <FlipkartProfiles /> : null}
        {window.location.href.includes('instamart') ? <InstamartProfiles /> : null}
        {window.location.href.includes('myntra') ? <MyntraProfiles /> : null}
        {window.location.href.includes('blinkit') ? <BlinkitProfiles /> : null}

        {process.env.REACT_APP_BUG_ENABLE === 1 ? (
          <ListGroup.Item as="li" bsPrefix=" ">
            <BugModal header={1} />
          </ListGroup.Item>
        ) : null}

        <ListGroup.Item as="li" bsPrefix=" ">
          {!isFullScreen ? <Maximize size={18} onClick={enableFullscreen} className="themeicn cursor-pointer hover-theme" /> : null}
          {isFullScreen ? <Minimize size={18} onClick={enableFullscreen} className="themeicn cursor-pointer hover-theme" /> : null}
          {/* <i className="feather icon-maximize cursor-pointer hover-theme" onClick={enableFullscreen}></i> */}
        </ListGroup.Item>

        {/* <ListGroup.Item as="li" bsPrefix=" ">
            <Dropdown alignRight={!rtlLayout} show={showDropdown} onToggle={setDropdown}>
              <Dropdown.Toggle as={Link} variant="link" to="#" id="dropdown-basic" onClick={setDropdown}>
                
                <Bell size={18} className="themeicn cursor-pointer hover-theme" />
              </Dropdown.Toggle>
              <Dropdown.Menu alignRight className="themebg notification notification-scroll">
                <div className="noti-head">
                  <h6 className="d-inline-block m-b-0 text-dark">Notifications</h6>
                  <p onClick={setDropdown}>Close</p>
                </div>

                <div className="noti-footer">
                  <Link className="text-dark" to="#">No Notifications here</Link>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </ListGroup.Item> */}

        {process.env.REACT_APP_THEME_ENABLE === '1' && (
          <ListGroup.Item as="li" bsPrefix=" ">
            <ThemeSwitcher />
            <StyleTag />
          </ListGroup.Item>
        )}

        <ListGroup.Item as="li" bsPrefix=" ">
            <Dropdown alignRight={!rtlLayout} className="drp-user" show={showProfDropdown} onToggle={setProfDropdown}>
              <Dropdown.Toggle as="li" id="dropdown-basic" onClick={setProfDropdown}>
                {/* <i className="feather icon-user" /> */}
                <User size={18} className="themeicn cursor-pointer hover-theme" />
              </Dropdown.Toggle>
              <Dropdown.Menu alignRight className="profile-notification">
                <div className="pro-head d-flex align-items-center f-w-700">
                  {/* <img src={userImg} className="img-radius mr-3" alt="User Profile" /> */}
                  <span>
                    {commonState?.userInfo?.fullName}
                  </span>
                </div>
                <ListGroup as="ul" bsPrefix=" " variant="flush" className="pro-body list-pad themebg">
                  {!window.location.href.includes('flipkart') || !window.location.href.includes('instamart') ? (
                    <ListGroup.Item as="li" bsPrefix=" " onClick={setProfDropdown}>
                      <Link to="/profile" className="themeicn dropdown-item f-w-500 d-flex align-items-center">
                        <User size={15} className="themeicn cursor-pointer mr-2" /> Account Access
                      </Link>
                    </ListGroup.Item>
                  ) : null}
                  
                  {localSelectedProfileId && (window.location.href.includes('flipkart') || window.location.href.includes('instamart') || window.location.href.includes('amazon') || window.location.href.includes('myntra') || window.location.href.includes('blinkit')) && localSelectedProfileId?.accessLevel !== 'Executive' && (
                    <ListGroup.Item as="li" bsPrefix=" " onClick={setProfDropdown}>
                      <Link to={`/${window.location.href.includes('flipkart') ? 'flipkart' : window.location.href.includes('instamart') ? 'instamart' : window.location.href.includes('myntra') ? 'myntra' : window.location.href.includes('amazon') ? 'amazon' : window.location.href.includes('blinkit') ? 'blinkit' : ''}-user-management`} className="themeicn dropdown-item f-w-500 d-flex align-items-center">
                        <UserCheck size={15} className="themeicn cursor-pointer mr-2" /> User Management
                      </Link>
                    </ListGroup.Item>
                  )}

                {isCategorizationShow &&
                  !window.location.href.includes('blinkit') &&
                  process.env.REACT_APP_CATEGORIZATION_ENABLE === '1' &&
                  localSelectedProfileId?.accessLevel !== 'Executive' &&
                  !window.location.href.includes('/profile') && ( // Condition to check for /profile
                    <ListGroup.Item as="li" bsPrefix=" " onClick={setProfDropdown}>
                      <Link
                        to={window.location.href.indexOf('/amazon') > -1 || window.location.href.indexOf('/amazon-master-overview') > -1 ? '/amazon-categorization' : window.location.href.includes('flipkart') ? '/flipkart-categorization' :  window.location.href.includes('instamart') ? '/instamart-categorization' :  window.location.href.includes('myntra') ? '/myntra-categorization' : '/blinkit-categorization' }
                        className="themeicn dropdown-item f-w-500 d-flex align-items-center"
                      >
                        <FileText size={15} className="themeicn cursor-pointer mr-2" />{' '}
                        {window.location.href.includes('flipkart') ? 'Product' : window.location.href.includes('instamart') ? 'Product' : window.location.href.includes('amazon') ? 'Asin' : window.location.href.includes('myntra') ? 'Product' : ''} Categorization
                      </Link>
                    </ListGroup.Item>
                  )}

                  {isCategorizationShow && process.env.REACT_APP_KEYWORD_CATEGORIZATION_ENABLE === '1' &&
                    localSelectedProfileId?.accessLevel !== 'Executive' &&
                    !window.location.href.includes('/profile') && (
                      <ListGroup.Item as="li" bsPrefix=" " onClick={setProfDropdown}>
                        <Link to={window.location.href.indexOf('/amazon') > -1 || window.location.href.indexOf('/amazon-master-overview') > -1 ? '/amazon-text-categorization' : window.location.href.includes("flipkart") ? "/flipkart-text-categorization" : window.location.href.includes("instamart") ? "/instamart-text-categorization" : window.location.href.includes("myntra") ? "/myntra/text-categorization" : window.location.href.includes("blinkit") ? "/blinkit/text-categorization" : ""} className="dropdown-item f-w-500 d-flex align-items-center">
                          <User size={15} className="cursor-pointer mr-2" /> Text Categorization
                        </Link>
                      </ListGroup.Item>
                    )}

                {isCategorizationShow && !window.location.href.includes("flipkart")  && localSelectedProfileId?.accessLevel !== 'Executive' && !window.location.href.includes('/profile') && (
                  <ListGroup.Item as="li" bsPrefix=" " onClick={setProfDropdown}>
                    <Link to="/amazon-blacklist-keywords" className="dropdown-item f-w-500 d-flex align-items-center">
                      <CloudDrizzle size={15} className="cursor-pointer mr-2" /> Blacklist Keywords
                    </Link>
                  </ListGroup.Item>
                )}

                {/* {isCategorizationShow && !window.location.href.includes("flipkart") && localSelectedProfileId?.accessLevel !== 'Executive' && (
                    <ListGroup.Item as="li" bsPrefix=" " onClick={setProfDropdown}>
                      <Link to="/rules" className="dropdown-item f-w-500 d-flex align-items-center">
                        <CloudDrizzle size={15} className="cursor-pointer mr-2" /> Automated Rules
                      </Link>
                    </ListGroup.Item>
                  )} */}

                {/* {isCategorizationShow && !window.location.href.includes("flipkart") && localSelectedProfileId?.accessLevel !== 'Executive' && (
                    <ListGroup.Item as="li" bsPrefix=" " onClick={setProfDropdown}>
                      <Link to="/blacklist" className="dropdown-item f-w-500 d-flex align-items-center">
                        <Settings size={15} className="cursor-pointer mr-2" /> Blacklist
                      </Link>
                    </ListGroup.Item>
                  )} */}

                  {localSelectedProfileId?.accessLevel?.toLowerCase() != 'executive' && localSelectedProfileId?.accessLevel?.toLowerCase() != 'manager' && (
                    <ListGroup.Item as="li" bsPrefix=" " onClick={setProfDropdown}>
                      <Link to="/account-settings" className="dropdown-item f-w-500 d-flex align-items-center">
                        <Crosshair size={15} className="cursor-pointer mr-2" /> Billing
                      </Link>
                    </ListGroup.Item>
                  )}

                {/* 
                  {!window.location.href.includes("flipkart") && process.env.REACT_APP_KEYWORD_CATEGORIZATION_ENABLE === '1' && localSelectedProfileId?.accessLevel !== 'Executive' && (
                    <ListGroup.Item as="li" bsPrefix=" " onClick={setProfDropdown}>
                      <Link to={window.location.href.includes("flipkart") ? "flipkart-text-categorization" : "/text-categorization"} className="dropdown-item f-w-500 d-flex align-items-center">
                        <User size={15} className="cursor-pointer mr-2" /> Text Categorization
                      </Link>
                    </ListGroup.Item>
                  )}

                  
        {/* 
                  {!window.location.href.includes("flipkart") && ParseData?.email !== 'admin@hectorai.live' && localSelectedProfileId?.accessLevel && localSelectedProfileId?.accessLevel.toLowerCase() === 'admin' && (
                    <ListGroup.Item as="li" bsPrefix=" " onClick={setProfDropdown}>
                      <Link to="/account-settings" className="themeicn dropdown-item f-w-500 d-flex align-items-center">
                        <Settings size={15} className="themeicn cursor-pointer mr-2" /> Settings
                      </Link>
                    </ListGroup.Item>
                  )} */}

                <ListGroup.Item as="li" bsPrefix=" " className="themebg cursor-pointer">
                  <a className="themeicn dropdown-item f-w-500 d-flex align-items-center" onClick={handleLogoutHandle}>
                    <LogOut size={15} className="themeicn cursor-pointer mr-2" />
                    Logout
                  </a>
                  <LogoutModal show={showLogoutModal} onHide={handleCloseLogoutModal} handleLogout={handleLogout} loader={doLogoutSettings?.loading} />
                </ListGroup.Item>
              </ListGroup>
            </Dropdown.Menu>
          </Dropdown>
        </ListGroup.Item>
      </ListGroup>

      <ChatList listOpen={listOpen} closed={() => setListOpen(false)} />
      {/* </ThemeProvider> */}
    </React.Fragment>
  );
};

export default NavRight;

const LogoutModal = ({ show, onHide, handleLogout, loader }) => {
  return (
    <Modal className="asin-modal" dialogClassName="new-theme" show={show} onHide={onHide} centered>
      <Modal.Body className="pt-4" style={{ borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }}>
        <p className="f-16 f-w-600 py-1 text-center">Are you sure you want to logout?</p>
      </Modal.Body>
      <Modal.Footer className="gap-10 border-none justify-content-center pb-4">
        <Button className="btn f-14 cls-btn py-2 px-3 f-13 border-none" onClick={onHide}>
          Cancel
        </Button>
        <Button className="btn f-14 bg-blue text-white py-2 px-3 f-13 border-none d-flex align-items-center gap-10" onClick={handleLogout}>
          Logout
          {loader && 
          <Spinner animation="border" role="status" className='wid-15 hei-15'>
          </Spinner>
          }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
